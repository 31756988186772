import React from "react";
import "./service-social.scss";
import chairs from "../../assets/service-social/chairs-dinner-svgrepo-com 1.png";
import flooring from "../../assets/service-social/draws-floor-svgrepo-com 1.png";
import furnitureassembly from "../../assets/service-social/wheelbarrow-svgrepo-com 1.png";
import painting from "../../assets/service-social/painted-paint-svgrepo-com 1.png";
import applianceinstallation from "../../assets/service-social/house-things-vacuum-svgrepo-com 1.png";
import interiortrim from "../../assets/service-social/hacksaw-svgrepo-com 1.png";
import bathroomrepairrefresh from "../../assets/service-social/relax-bathroom-svgrepo-com 1.png";
import windowsdoorinstallation from "../../assets/service-social/door-svgrepo-com 1.png";
import worktool from "../../assets/service-social/work-tools-svgrepo-com 1.png";
import plansplanning from "../../assets/service-social/plans-planning-svgrepo-com 1.png";
import preventivemaintenance from "../../assets/service-social/worker-helmet-svgrepo-com 1.png";
import mechanicsscrewdriver from "../../assets/service-social/mechanics-screwdriver-svgrepo-com 1.png";
import { Link } from "react-router-dom";

const ServiceSocial = () => {
  return (
    <div className="service-social">
      <div className="container">
        <div class="service-social">
          <Link to={"/services/kitchens"}>
            <div class="icon-box">
              <img src={chairs} alt="chairs" />
              <p>Kitchen repair & refresh </p>
            </div>
          </Link>
          <Link to={"services/flooring"}>
            <div class="icon-box">
              <img src={flooring} alt="doors" />
              <p>Flooring </p>
            </div>
          </Link>
          <Link to={"/services/furniture-assembly"}>
            <div class="icon-box">
              <img src={furnitureassembly} alt="doors" />
              <p>furniture assembly </p>
            </div>
          </Link>
          <Link to={"services/painting"}>
            <div class="icon-box">
              <img src={painting} alt="doors" />
              <p>Painting </p>
            </div>
          </Link>
          <Link to={"/services/appliance"}>
            <div class="icon-box">
              <img src={applianceinstallation} alt="doors" />
              <p>appliance installation </p>
            </div>
          </Link>
          <Link to={"/services/plumbing"}>
            <div class="icon-box">
              <img src={interiortrim} alt="doors" />
              <p>Plumbing</p>
            </div>
          </Link>
          <Link to={"/services/bathrooms"}>
            <div class="icon-box">
              <img src={bathroomrepairrefresh} alt="doors" />
              <p>bathroom repair & refresh </p>
            </div>
          </Link>
          <Link to={"/services/doors"}>
            <div class="icon-box">
              <img src={windowsdoorinstallation} alt="doors" />
              <p>door installation </p>
            </div>
          </Link>
          <Link to={"/services/drywall"}>
            <div class="icon-box">
              <img src={worktool} alt="doors" />
              <p>dry wall </p>
            </div>
          </Link>
          <Link to={"/services/windows"}>
            <div class="icon-box">
              <img src={windowsdoorinstallation} alt="doors" />
              <p>windows installation </p>
            </div>
          </Link>
          {/* <Link to={"#"}>
            <div class="icon-box">
              <img src={plansplanning} alt="doors" />
              <p>plans-planning-svgrepo-com 1 </p>
            </div>
          </Link> */}
          <Link to={"services/preventative-maintenance"}>
            <div class="icon-box">
              <img src={preventivemaintenance} alt="doors" />
              <p>preventive maintenance </p>
            </div>
          </Link>
          <Link to={"services/testing-services"}>
            <div class="icon-box">
              <img src={mechanicsscrewdriver} alt="doors" />
              <p>Testing Services</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceSocial;
