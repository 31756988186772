import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonialslider.scss"; // Your custom styles for the slider
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

const TestimonialSlider = ({ testimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <IoMdArrowBack />,
    nextArrow: <IoMdArrowForward />,
    
  };

  return (
    <div className="testimonial-slider">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div className="images">
              <img
                src={testimonial.firstImageUrl}
                alt={`First Image ${index}`}
              />
            </div>
            <p>{testimonial.quote}</p>
            <div className="admin">
              <img
                src={testimonial.secondImageUrl}
                alt={`Second Image ${index}`}
              />
              <div className="admin-content">
                <h3>{testimonial.author}</h3>
                <p>{testimonial.id}</p> 
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
