import React, { useState } from "react";
import "./wall-services.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const WallServices = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="wall-services">
      <div className="services-questions">
        <div className="container">
          <div className="head">
            <h4>
              <span class="line"></span>{data.title}
            </h4>
            {/* <h3>Bathroom Repair & Refresh</h3> */}
            <p>
              {data.desc}
            </p>
          </div>
          <div className="accordion">
            {data.accordions.map((item, index) => (
              <div className="accordion-title" key={index}  style={{
                backgroundColor: openIndex === index ? " var(--third-color-two)" : "transparent",
              }}>
                <button
                  onClick={() => toggleAccordion(index)}
                  className="accordion-button"
                >
                  <span>{item.title}</span>
                  <span>
                    {openIndex === index ? (
                      <IoIosArrowUp className="icon" />
                    ) : (
                      <IoIosArrowDown className="icon" />
                    )}
                  </span>
                </button>
                {openIndex === index && (
                  <div className="accordion-desc">
                    <p>{item.desc}</p>
                    {item.bullets&&(
                      <ul>
                        {item.bullets.map(pointer => (
                          <li>{pointer}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WallServices;
