import React from "react";
import "./hero-content.scss";
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";

const HeroContent = ({ data }) => {
  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };
  return (
    <div className="hero-content">
      <div className="container">
        <div className="head">
          <h4>
            <span class="line"></span>
            {data.subheading}
          </h4>
          <h3>{data.heading}</h3>
          <p>{data.desc}</p>
          <button
            data-token="765abcf35e624c58b5680fa0a1ca9a95"
            data-orgname="STL-HandyPros"
            class="hcp-button"
            onClick={openModal}
          >
            <p>{data.buttonName}</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroContent;
