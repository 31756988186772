import React from "react";
import "./services.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import ServiceSocial from "../../components/service-social/ServiceSocial";
import CTA from "../../components/cta-section/CTA";
import ServiceCard from "../../components/services-cards/ServiceCard";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";
import { services } from "../../assets/data/services.js";
import occupancy from "../../assets/images/occupancy.png";

const Services = () => {
  const heroContent = {
    subheading: "our services",
    heading: "Why Hire a Handyman When You Can Hire a HandyPro!",
    desc: "In every household, there's a list of home repair, maintenance, and improvement projects waiting to be tackled—indoors and outdoors. But in the whirlwind of today's busy lifestyles, finding the time or patience to handle it all alone can be a challenge. Let STL HandyPros step in and ensure your home runs seamlessly with our diverse range of repair, improvement, and remodeling services.",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };
  return (
    <AnimationWrapper>
      <div className="services">
        <div className="section hero-banner">
          <div className="row">
            <HeroContent data={heroContent} />
            {/* <ServiceSocial /> */}
          </div>
        </div>

        <div className="section services-cards">
          <div className="container">
            <div className="head">
              <h4>
                <span class="line"></span>all services
              </h4>
              <h3>HandyPro Services</h3>
              <p>
                Explore the full spectrum of services offered by STL HandyPros,
                meticulously designed to meet every aspect of your home
                improvement needs. From skilled expertise to attentive repairs,
                our 'All Services' section serves as your comprehensive
                destination for transforming and maintaining your home. Trust us
                as the ultimate solution for enhancing and caring for your
                living space.
              </p>
            </div>
            <div className="service-list">
              <div className="service-row">
                {services.map((services, index) => (
                  <ServiceCard
                    key={services.id}
                    image={services.thumbnail}
                    heading={services.title}
                    description={services.desc}
                    link={services.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="section extra">
          <div className="container">
            <div className="row">
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>STL HandyPros
                  </h4>
                  <h3>STL HandyPros and Occupancy / Section 8 Assistance</h3>
                  <p>
                    STL HandyPros offers expert assistance for occupancy and
                    Section 8 inspections, ensuring your property meets all
                    necessary standards. Our skilled team is adept at addressing
                    inspection requirements, conducting thorough assessments,
                    and making necessary repairs or modifications to meet
                    compliance. Whether it's ensuring safety measures, fixing
                    structural issues, or making cosmetic enhancements, our
                    professionals understand the specific criteria and work
                    diligently to prepare your property for successful
                    inspections. Count on STL HandyPros for comprehensive
                    support to ensure your property passes inspections smoothly
                    and efficiently.
                  </p>
                </div>
              </div>
              <div class="column">
                <img src={occupancy} alt="homemaintain" />
              </div>
            </div>
          </div>
        </div>

        <CTA
          heading={"contact us"}
          subheading={
            "Own a Piece of the Brand America Trusts – Franchises Available"
          }
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025."
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};

export default Services;
