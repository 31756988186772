import React, { useState } from "react";
import "./living-room-compersion.scss";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Addaccesspanelbefore from "../../assets/Before and after pictures/Add access panel - before.jpeg";
import Addaccesspanelafter from "../../assets/Before and after pictures/Add access panel - after.jpeg";
import Basketballhoopinstallbefore from "../../assets/Before and after pictures/Basketball hoop install- before.jpeg";
import Basketballhoopinstallafter from "../../assets/Before and after pictures/Basketball hoop install- after.jpeg";
const LivingRoomCompersion = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="living-room-compersion">
      <div className="main-living-room-compersion">
        <div className="inner-tabs">
          <div className="container">
            <div className="inner-tabs-container">
              <div className="inner-tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  Living Room
                </button>
                <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Living Room
                </button>
                <button
                  className={activeTab === 3 ? "active-tab" : ""}
                  onClick={() => handleTabClick(3)}
                >
                  Living Room
                </button>
                <button
                  className={activeTab === 4 ? "active-tab" : ""}
                  onClick={() => handleTabClick(4)}
                >
                  Living Room
                </button>
                <button
                  className={activeTab === 5 ? "active-tab" : ""}
                  onClick={() => handleTabClick(5)}
                >
                  Living Room
                </button>
                <button
                  className={activeTab === 6 ? "active-tab" : ""}
                  onClick={() => handleTabClick(6)}
                >
                  Living Room
                </button>
              </div>
              <div className="tab-content">
                {activeTab === 1 && (
                  <AnimationWrapper>
                    <ReactCompareSlider style={{ height: "700px" }}
                      itemOne={
                        <ReactCompareSliderImage
                         style={{ objectFit: "cover" }} 
                          src={Addaccesspanelbefore}
                          srcSet={Addaccesspanelbefore}
                          alt="Image one"
                        />
                      }
                      itemTwo={
                        <ReactCompareSliderImage
                         style={{ objectFit: "cover" }} 
                          src={Addaccesspanelafter}
                          srcSet={Addaccesspanelafter}
                          alt="Image two"
                        />
                      }
                    />
                  </AnimationWrapper>
                )}
                {activeTab === 2 && <AnimationWrapper>
                    <ReactCompareSlider style={{ height: "700px" }}
                      itemOne={
                        <ReactCompareSliderImage
                         style={{ objectFit: "center" }} 
                          src={Basketballhoopinstallbefore}
                          srcSet={Basketballhoopinstallbefore}
                          alt="Image one"
                        />
                      }
                      itemTwo={
                        <ReactCompareSliderImage
                         style={{ objectFit: "center" }} 
                          src={Basketballhoopinstallafter}
                          srcSet={Basketballhoopinstallafter}
                          alt="Image two"
                        />
                      }
                    />
                    </AnimationWrapper>}
                {activeTab === 3 && <AnimationWrapper>hello</AnimationWrapper>}
                {activeTab === 4 && <AnimationWrapper>hello</AnimationWrapper>}
                {activeTab === 5 && <AnimationWrapper>hello</AnimationWrapper>}
                {activeTab === 6 && <AnimationWrapper>hello</AnimationWrapper>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivingRoomCompersion;
