import React, { useState } from "react";
import "./find-a-craftsman.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import FormalCards from "../../components/formal-cards/FormalCards";
import oneone from "../../assets/images/oneone.png";
import twotwo from "../../assets/images/twotwo.png";
import threethree from "../../assets/images/threethree.png";
import fourfour from "../../assets/images/fourfour.png";
import fivefive from "../../assets/images/fivefive.png";
import sixsix from "../../assets/images/sixsix.png";
import ScheduleAppointment from "../../components/schedule-appointment/ScheduleAppointment";
import GetAnEstimate from "../../components/get-an-estimate/GetAnEstimate";
import craftsmen from "../../assets/images/craftsmen.png";
import CTA from "../../components/cta-section/CTA";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";

const FindACraftsman = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const heroContent = {
    subheading: "hire a Pro",
    heading: "Discover Your Perfect Match",
    desc: "Our HandyPros undergo a thorough background check, are bonded and insured, and have verified skills. Once your job is complete, you will receive receipts and photos along with your invoice, providing transparent pricing, quality work and peace of mind.",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };
  const cardsInfo = [
    {
      id: 1,
      link: "#",
      image: oneone,
      heading: "We Listen & Solve:",
      description:
        "At STL HandyPros, we believe in listening to your needs and delivering effective solutions. Your satisfaction is our priority, and we're committed to addressing your concerns with tailored and efficient resolutions.",
    },
    {
      id: 2,
      link: "#",
      image: twotwo,
      heading: "On My Way Notifications:",
      description:
        "At STL HandyPros, transparency and communication are paramount. Our 'on-my-way' notifications keep you informed every step of the way. As our skilled professionals head to your location, expect real-time updates, ensuring you're aware of their arrival time. This commitment to transparent communication allows you to plan your day efficiently, knowing exactly when our team will be there to address your home repair or improvement needs.",
    },
    {
      id: 3,
      link: "#",
      image: threethree,
      heading: "Quick, Easy, Convenient Scheduling:",
      description:
        "Experience the convenience of quick and effortless online booking with STL HandyPros. Our user-friendly platform allows you to schedule your home repair or improvement services in just a few clicks. With easy access from any device, you can select your preferred service, choose an available time slot, and confirm your appointment seamlessly. No more waiting on hold or navigating complex processes—our streamlined online booking system puts you in control, ensuring a hassle-free experience as you arrange expert care for your home, effortlessly.",
    },
    {
      id: 4,
      link: "#",
      image: fourfour,
      heading: "Fair Pricing:",
      description:
        "At STL HandyPros, we prioritize flexibility and fairness in our service offerings. That's why we provide a minimum booking of just one hour, allowing you to access our expert services efficiently and affordably. We understand that every minute counts, which is why after the initial hour, our billing system operates in convenient 15-minute increments. This transparent approach ensures that you only pay for the time you need, providing you with cost-effective solutions and tailored service durations that match your specific home repair or improvement requirements.",
    },
    {
      id: 5,
      link: "#",
      image: fivefive,
      heading: "Commitment to Courteous Service:",
      description:
        "Respect for your home is at the core of STL HandyPros' service ethic. Our professionals uphold this by wearing foot booties, ensuring that your floors remain pristine during our work. Additionally, our team takes pride in leaving your space as clean as we found it, tidying up after completing the job. We believe in not just fixing or improving your home but also in maintaining its cleanliness and integrity throughout the service process, demonstrating our commitment to a thorough and respectful experience.",
    },
    {
      id: 6,
      link: "#",
      image: sixsix,
      heading: "Dependable, Background-Checked HandyPros:",
      description:
        "STL HandyPros takes pride in our team of extensively vetted and qualified Pros. Each member of our skilled team undergoes a rigorous background check to ensure trustworthiness and reliability. This commitment to thorough screening guarantees that our Pros are not only qualified in their expertise but also possess the integrity and professionalism that align with our standards. Rest assured, when you choose STL HandyPros, you're choosing a team of dedicated experts who are both qualified and trustworthy to handle your home repair and improvement needs.",
    },
  ];
  return (
    <AnimationWrapper>
      <div className="find-a-craftsman">
        <div className="section hero-banner">
          <div className="row">
            <HeroContent data={heroContent} />
          </div>
        </div>

        <div className="section services">
          <div className="container">
            <div className="head">
              <h4>
                <span class="line"></span>100% Satisfied customers
              </h4>
              <h3>Why Choose STL Handypros Services</h3>
              <p>
                At STL HandyPros, we stand out for our commitment to excellence.
                With our skilled team, prompt service, and dedication to
                customer satisfaction, choosing us means choosing reliability,
                expertise, and a hassle-free experience for all your home
                maintenance and improvement needs.
              </p>
            </div>
            <div className="info-list">
              <div className="info-row">
                {cardsInfo.map((card, index) => (
                  <FormalCards
                    key={card.id}
                    link={card.link}
                    image={card.image}
                    heading={card.heading}
                    description={card.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="section tabs">
          <div className="container">
            <div className="tabs-container">
              <div className="tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  Schedule Appointment
                </button>
                {/* <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Get An Estimate
                </button> */}
              </div>
              <div className="tab-content">
                {activeTab === 1 && <ScheduleAppointment />}
                {/* {activeTab === 2 && <GetAnEstimate />} */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="section extra">
          <div className="container">
            <div className="row">
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>completed projects
                  </h4>
                  <h3>
                    Our Multi-Skilled Handypros can complete 1,162 projects,
                    saving you time
                  </h3>
                  <p>Working with and for you by embodying:</p>
                  <ul>
                    <li>Timeliness</li>
                    <li>Respectfulness</li>
                    <li>Responsibility</li>
                    <li>Attentiveness</li>
                    <li>Honesty</li>
                    <li>Politeness</li>
                    <li>Patience</li>
                  </ul>
                  <p>
                    STL HandyPros outstanding Pros are dedicated to exceeding
                    your expectations with their exceptional commitment.
                  </p>
                </div>
              </div>
              <div class="column">
                <img src={craftsmen} alt="handypros" />
              </div>
            </div>
          </div>
        </div> */}

        <CTA
          heading={"Book Now"}
          subheading={
            "Own a Piece of the Brand America Trusts – Franchises Available"
          }
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025."
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};

export default FindACraftsman;
