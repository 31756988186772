import React from "react";
import "./cta.scss";
import ctaman from "../../assets/images/helmet-man.png";
import { GoArrowUpRight } from "react-icons/go";

const CTA = ({ heading, subheading, desc, buttonName }) => {
  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };
  return (
    <div className="cta">
      <div className="section main-cta">
        <div className="container inner-cta">
          <div className="column larger">
            <div className="content">
              <h4>
                <span class="line"></span>
                {heading}
              </h4> 
              <h3>{subheading}</h3>
              <p>{desc}</p>
              <button
                data-token="765abcf35e624c58b5680fa0a1ca9a95"
                data-orgname="STL-HandyPros"
                class="hcp-button"
                onClick={openModal}
              >
                <p>{buttonName}</p>
                <div className="arrow-icon-box">
                  <GoArrowUpRight className="upright-arrow-icon" />
                </div>
              </button>
            </div>
          </div>
          <div className="column small">
            <img src={ctaman} alt="ctaman" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
