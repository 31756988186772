import React, { useEffect, useState } from "react";
import "./service-detail-page.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import ScheduleAppointment from "../../components/schedule-appointment/ScheduleAppointment";
import CTA from "../../components/cta-section/CTA";
import WallServices from "../../components/walls-services/WallServices";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa6";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";
import { services } from "../../assets/data/services";

const ServiceDetailPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [anotheractiveTab, setAnotherActiveTab] = useState(1);
  const [pageData, setPageData] = useState(
    services.find((service) => service.id === id)
  );

  const { title, desc, accordions } = pageData;

  useEffect(() => {
    let newData = services.find((service) => service.id === id);
      setPageData(newData);
  },[ location.pathname])

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const AnotherhandleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const heroContent = {
    subheading: "Services",
    heading: title,
    desc: "",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };

  const accordionContent = [
    {
      title: "Cabinet Installation & Repair",
      Desc: " Adding a backsplash in a kitchen is a trendy and fashionable way to add color and dimension to your kitchen. This type of project can be challenging, which is why our professionals at Ace Handyman Services are trained and possess the necessary tools to do this job and do it right! We can help you find the correct pattern and look to bring out the natural beauty and personality of your kitchen. Call us today to discuss your backsplash project!",
    },
    {
      title: "Kitchen Backsplash Installation",
      Desc: " Adding a backsplash in a kitchen is a trendy and fashionable way to add color and dimension to your kitchen. This type of project can be challenging, which is why our professionals at Ace Handyman Services are trained and possess the necessary tools to do this job and do it right! We can help you find the correct pattern and look to bring out the natural beauty and personality of your kitchen. Call us today to discuss your backsplash project!",
    },
    {
      title: "Flooring",
      Desc: " Adding a backsplash in a kitchen is a trendy and fashionable way to add color and dimension to your kitchen. This type of project can be challenging, which is why our professionals at Ace Handyman Services are trained and possess the necessary tools to do this job and do it right! We can help you find the correct pattern and look to bring out the natural beauty and personality of your kitchen. Call us today to discuss your backsplash project!",
    },
    {
      title: "Pantry",
      Desc: " Adding a backsplash in a kitchen is a trendy and fashionable way to add color and dimension to your kitchen. This type of project can be challenging, which is why our professionals at Ace Handyman Services are trained and possess the necessary tools to do this job and do it right! We can help you find the correct pattern and look to bring out the natural beauty and personality of your kitchen. Call us today to discuss your backsplash project!",
    },
  ];

  return (
    <AnimationWrapper>
      <div className="service-detail-page">
        <div className="section hero-banner">
          <div className="row">
            <HeroContent data={heroContent} />
          </div>
        </div>

        <div className="section service-tabs">
          <div className="container inner-wrapper">
            <div className="tabs-details">
              <div className="tab-buttons-details">
                {/* <div className="tab-buttons-details-col-one">
                  <h6>Related Services</h6>
                  <button
                    className={anotheractiveTab === 1 ? "active-tab" : ""}
                    onClick={() => AnotherhandleTabClick(1)}
                  >
                    Walls
                  </button>
                  <button
                    className={anotheractiveTab === 2 ? "active-tab" : ""}
                    onClick={() => AnotherhandleTabClick(2)}
                  >
                    Floors
                  </button>
                  <button
                    className={anotheractiveTab === 3 ? "active-tab" : ""}
                    onClick={() => AnotherhandleTabClick(3)}
                  >
                    Painting & Staining
                  </button>
                  <button
                    className={anotheractiveTab === 4 ? "active-tab" : ""}
                    onClick={() => AnotherhandleTabClick(4)}
                  >
                    Remodeling
                  </button>
                  <button
                    className={anotheractiveTab === 5 ? "active-tab" : ""}
                    onClick={() => AnotherhandleTabClick(5)}
                  >
                    Preventative Maintenance
                  </button>
                  <button
                    className={anotheractiveTab === 6 ? "active-tab" : ""}
                    onClick={() => AnotherhandleTabClick(6)}
                  >
                    Home Repair & Upkeep
                  </button>
                </div> */}
                <div className="tab-buttons-details-col-two">
                  <h2>Still looking for more services?</h2>
                  <p>
                    Just because it’s not listed doesn’t mean that we don’t do
                    it! Check the list below or give us a call for more answers.
                  </p>

                  <Link to={"tel:+13148041097"}>
                    <p>314-804-1097</p>
                    <div className="arrow-icon-box">
                      <FaPhoneVolume className="upright-arrow-icon" />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="tab-content">
                {anotheractiveTab === 1 && (
                  <WallServices data={pageData} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section tabs">
          <div className="container">
            <div className="tabs-container">
              <div className="tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  Schedule Appointment
                </button>
                {/* <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Get An Estimate
                </button> */}
              </div>
              <div className="tab-content">
                {activeTab === 1 && <ScheduleAppointment />}
                {/* {activeTab === 2 && <GetAnEstimate />} */}
              </div>
            </div>
          </div>
        </div>

        <CTA
          heading={"contact us"}
          subheading={
            "Own a Piece of the Brand America Trusts – Franchises Available"
          }
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025."
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};

export default ServiceDetailPage;
