import React, { useEffect } from "react";
import "./schedule-appointment.scss";
import { GoArrowUpRight } from "react-icons/go";
import appointment from "../../assets/images/appointment.png";
import imgone from "../../assets/images/imgone.png";
import imgtwo from "../../assets/images/imgtwo.png";
import wobbleguy from "../../assets/images/wobble-guy.png";
import { Link } from "react-router-dom";

const ScheduleAppointment = () => {
  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };
  
  return (
    <div className="schedule-appointment">
      <div className="container main-container">
        <div className="row">
          <div className="col larger">
            <div className="head">
              <h4>
                <span class="line"></span>let’s schedule it
              </h4>
              <h3>We'll Schedule Your Appointment!</h3>
              <p>
                Ready to enhance your home? Schedule an appointment now and let
                our experienced team take care of your repair and improvement
                needs. Your satisfaction is our commitment!
              </p>
              <button
                data-token="765abcf35e624c58b5680fa0a1ca9a95"
                data-orgname="STL-HandyPros"
                class="hcp-button"
                onClick={openModal}
              >
                <p>Schedule Appointment</p>
                <div className="arrow-icon-box">
                  <GoArrowUpRight className="upright-arrow-icon" />
                </div>
              </button>
            </div>
            <div className="img-container">
              <img src={imgone} alt="imgone" className="imgone" />
              <img src={imgtwo} alt="imgtwo" className="imgtwo" />
            </div>
          </div>
          <div className="col small">
            <img src={wobbleguy} alt="appointment" />
            <h4>call us at</h4>
            <Link to={"tel:+13148041097"}>
              {" "}
              <h6>+1 866-574-2398</h6>
            </Link>
            <p>Always locally owned & independently operated.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointment;
