import React, { useState } from "react";
import "./contact-us.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import form from "../../assets/images/form.png";
import CTA from "../../components/cta-section/CTA";
import ScheduleAppointment from "../../components/schedule-appointment/ScheduleAppointment";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";

const ContactUs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };

  const heroContent = {
    subheading: "Get in Touch",
    heading: "Learn More",
    desc: "Reach out to STL HandyPros for all your inquiries, including questions, online estimates, investor walk-through inspections, homeowner needs, and tenant requirements. We're here to assist you with comprehensive solutions and expert service.",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    projectInfo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);
  };
  return (
    <AnimationWrapper>
      <div className="contact-us">
        <div className="section hero-banner">
          <div className="row">
            <HeroContent data={heroContent} />
          </div>
        </div>

        <div className="section deatils">
          <div className="container">
            <div className="detail-container">
              <div class="column">
                <div className="content-div">
                  <FaEnvelope />
                  <h5>Email</h5>
                  <p>
                    For inquiries or assistance, feel free to contact us via
                    email. We're here to address your questions and provide the
                    information you need!
                  </p>
                  <Link to="mailto:STLHandyPros@gmail.com">
                  info@stlhandypros.com
                  </Link>
                </div>
              </div>

              <div class="column">
                <div className="content-div">
                  <FaPhoneAlt />
                  <h5>Phone</h5>
                  <p>
                    Have questions or need assistance? Give us a call and the
                    STL HandyPros team will be ready to help you with expert
                    advice and efficient service.
                  </p>
                  <Link to={"tel:+13148041097"}>314-804-1097</Link>
                </div>
              </div>

              <div class="column">
                <div className="content-div">
                  <FaLocationDot />
                  <h5>Book Instantly!</h5>
                  <p>
                    Experience the convenience of instant online booking! Visit
                    our website to seamlessly schedule your service with STL
                    HandyPros in just a few clicks. Your home improvement
                    journey is just a booking away!
                  </p>
                  <Link
                    to={"#"}
                    data-token="765abcf35e624c58b5680fa0a1ca9a95"
                    data-orgname="STL-HandyPros"
                    class="hcp-button"
                    onClick={openModal}
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="section contact-form">
          <div className="container">
            <div className="head">
              <h4>
                <span class="line"></span>100% Satisfied customers
              </h4>
              <h3>Why Choose STL Handypros Services</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur. Lorem placerat aliquam
                et a eget diam tellus at. Ac risus non commodo tincidunt. Quis
                in gravida etiam amet congue. Morbi erat elementum vulputate
                tincidunt eu accumsan sed mattis.
              </p>
            </div>
            <div className="row">
              <div className="col larger">
                <form onSubmit={handleSubmit}>
                  <div className="form-control">
                    <div className="form-flex">
                      <label htmlFor="firstName">First Name:</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-flex">
                      <label htmlFor="lastName">Last Name:</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-control">
                    <div className="form-flex">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-flex">
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-flex">
                    <label htmlFor="projectInfo">Message</label>
                    <textarea
                      id="projectInfo"
                      name="projectInfo"
                      value={formData.projectInfo}
                      onChange={handleChange}
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>

                  <div className="a">
                    <input type="submit" value="Submit Response" />
                    <div className="arrow-icon-box">
                      <GoArrowUpRight className="upright-arrow-icon" />
                    </div>
                  </div>
                </form>
              </div>
              <div className="col small">
                <img src={form} alt="form" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="section tabs">
          <div className="container">
            <div className="tabs-container">
              <div className="tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  Schedule Appointment
                </button>
                {/* <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Get An Estimate
                </button> */}
              </div>
              <div className="tab-content">
                {activeTab === 1 && (
                  <AnimationWrapper>
                    <ScheduleAppointment />
                  </AnimationWrapper>
                )}
                {/* {activeTab === 2 && (
                  <AnimationWrapper>
                    <GetAnEstimate />
                  </AnimationWrapper>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <CTA
          heading={"Book Now"}
          subheading={
            "Own a Piece of the Brand America Trusts – Franchises Available"
          }
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025."
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};

export default ContactUs;
