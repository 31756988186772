import React from "react";
import "./testimonials.scss";
import TestimonialSlider from "../testimonial-slider/TestimonialSlider";
import avatar from "../../assets/images/avatar.png";
import stars from "../../assets/images/stars.png";

const Testimonials = () => {
  const testimonials = [
    {
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
    {
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
    {
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
    {
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
  ];
  return (
    <div className="testimonials">
      <div className="section">
        <div className="container">
          <div className="head">
            <h4>
              <span class="line"></span>testimonials
            </h4>
            <h3>Meet Client Satisfaction</h3>
            <p>
              At STL HandyPros, client satisfaction is our guarantee. We pride
              ourselves on ensuring that every experience with us exceeds your
              expectations
            </p>
          </div>
          <TestimonialSlider testimonials={testimonials} />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
