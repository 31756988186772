import React from "react";
import { Route, Routes } from "react-router-dom";
import Services from "../pages/services/Services";
import Home from '../pages/home/Home';
import ContactUs from "../pages/contact-us/ContactUs";
import HowItWorks from "../pages/how-it-works/HowItWorks";
import FindACraftsman from "../pages/find-a-craftsman/FindACraftsman";
import WhyChooseUs from "../pages/why-choose-us/WhyChooseUs";
import KitchenService from "../pages/kitchen-service/KitchenService";
import { Testimonials } from "../pages/testimonials/Testimonials";
import ServiceDetailPage from "../pages/service-dtail-page/ServiceDetailPage";
import AnnualMaintenancePlan from "../pages/annual-maintenance-plan/AnnualMaintenancePlan";
import AboutUs from "../pages/about-us/AboutUs";

const Routers = () => {
  return (
    <Routes>
      {/* main pages */}
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/find-a-pro" element={<FindACraftsman />} />
      <Route path="/why-choose-us" element={<WhyChooseUs />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/services/:id" element={<ServiceDetailPage />} />
      {/* <Route path="/annual-maintenance-plan" element={<AnnualMaintenancePlan />} /> */}
    </Routes>
  );
};

export default Routers;
