import React from "react";
import "./formal-cards.scss";
import { Link } from "react-router-dom";

const FormalCards = ({ link, image, heading, description }) => {
  return (
    <div className="formal-cards">
      <Link to={link}>
        <div className="content">
          <img src={image} alt={heading} />
          <h5>{heading}</h5>
          <p>{description}</p>
        </div>
      </Link>
    </div>
  );
};

export default FormalCards;
