export const mainNavLinks = [
  {
    name: "Home",
    path: "/",
    id: "a",
  },
  {
    name: "Services",
    path: "/services",
    id: "b",
    children: [
      {
        name: "Living Room",
        path: "/services/living-room",
        id: "b1",
      },
      {
        name: "Bedroom",
        path: "/services/bedroom",
        id: "b2",
      },
      {
        name: "Flooring",
        path: "/services/flooring",
        id: "b3",
      },
      {
        name: "Drywall, Interior Trim & Painting",
        path: "/services/drywall-interior-trim-and-painting",
        id: "b4",
      },
      {
        name: "Furniture Assembly",
        path: "/services/furniture-assembly",
        id: "b5",
      },
      {
        name: "Appliance Installation",
        path: "/services/appliance-installation",
        id: "b6",
      },
      {
        name: "Bathroom Repair & Refresh",
        path: "/services/bathroom-repair-and-refresh",
        id: "b7",
      },
      {
        name: "Kitchen Repair & Refresh",
        path: "/services/kitchen-repair-and-refresh",
        id: "b8",
      },
      {
        name: "Office",
        path: "/services/office",
        id: "b9",
      },
      {
        name: "Door Installation",
        path: "/services/door-installation",
        id: "b10",
      },
      {
        name: "Cabinet Installation & Repair",
        path: "/services/cabinet-installation-and-repair",
        id: "b11",
      },
      {
        name: "Backsplash and Countertops",
        path: "/services/backsplash-and-countertops",
        id: "b12",
      },
      {
        name: "Preventative Maintenance",
        path: "/services/preventative-maintenance",
        id: "b13",
      },
      {
        name: "Home Repair & Upkeep",
        path: "/services/home-repair-and-upkeep",
        id: "b14",
      },
      {
        name: "View All Services",
        path: "/services",
        id: "b15",
      },
    ],
  },
  {
    name: "Why Choose Us",
    path: "/why-choose-us",
    id: "c",
  },
  {
    name: "Find a Pro",
    path: "find-a-pro",
    id: "d",
  },
  {
    name: "Contact Us",
    path: "/contact-us",
    id: "e",
  },
  {
    name: "Testimonials",
    path: "/testimonials",
    id: "f",
  },
  {
    name: "How it works",
    path: "/how-it-works",
    id: "g",
  },
];
