import plumbing from "../../assets/images/services/plumbing.jpg";
import electrical from "../../assets/images/services/electrical.webp";
import flooring from "../../assets/images/services/flooring.jpg";
import drywall from "../../assets/images/services/drywall.jpg";
import painting from "../../assets/images/services/painting.jpg";
import furnitureAssembly from "../../assets/images/services/furniture-assembly.jpg";
import applianceInstallation from "../../assets/images/services/appliance-installation.jpg";
import waterheater from "../../assets/images/services/waterheater.jpg";
import punchlists from "../../assets/images/services/punchlists.jpg";
import bathroomRepair from "../../assets/images/services/bathroom-repair.jpg";
import decks from "../../assets/images/services/decks.jpg";
import sidingAndGutters from "../../assets/images/services/siding-and-gutters.jpg";
import concreteAndAsphalt from "../../assets/images/services/concrete-and-asphalt.jpg";
import kitchenRepair from "../../assets/images/services/kitchen-repair.jpg";
import windows from "../../assets/images/services/windows.jpg";
import doorInstallation from "../../assets/images/services/door-installation.jpg";
import preventative from "../../assets/images/services/preventative.jpg";
// import livingRoom from "../../assets/images/services/living-room.jpg";
// import livingRoom from "../../assets/images/services/living-room.jpg";
// import livingRoom from "../../assets/images/services/living-room.jpg";
// import bedroom from "../../assets/images/services/bedroom.jpg";
// import office from "../../assets/images/services/office.jpg";
// import cabinetInstallation from "../../assets/images/services/cabinet-installation.jpg";
// import backsplash from "../../assets/images/services/backsplash.jpg";
// import homeRepair from "../../assets/images/services/home-repair.jpg";

export const services = [
  {
    id: "plumbing",
    thumbnail: plumbing,
    title: "Plumbing",
    desc: "At HandyPros, we offer plumbing services to keep your home running smoothly. Whether you're dealing with a leaky faucet, clogged drains, or need a full fixture installation, our experienced and reliable team is here to help. We handle everything from minor repairs to larger plumbing projects with efficiency and care, ensuring your satisfaction every step of the way. Trust HandyPros for fast, dependable, and high-quality plumbing solutions that fit your needs and budget.",
    accordions: [
      {
        title: "P-Traps",
        desc: "HandyPros is here to ensure your P-traps are functioning properly, preventing unpleasant odors and keeping your plumbing system in top shape. Whether you're dealing with a clogged or leaking P-trap or need a replacement, our experienced HandyPros work efficiently, ensuring your home’s plumbing remains free of blockages and water damage.",
      },
      {
        title: "Toilet Installation",
        desc: "Need a new toilet installed? HandyPros offers fast, reliable toilet installation services that ensure your new fixture is set up correctly the first time. From removing old units to expertly installing new ones, we take care of everything with precision, leaving you with a fully functioning toilet that enhances the comfort and efficiency of your bathroom.",
      },
      {
        title: "Faucets",
        desc: "Whether you’re upgrading your bathroom or kitchen or dealing with a leaky faucet, HandyPros has you covered. Our professional faucet installation and repair services help enhance your home’s water efficiency and style. From modern faucets to classic designs, we handle all installations and repairs with care and attention to detail, ensuring everything works perfectly.",
      },
      {
        title: "Shut Offs and Valves",
        desc: "Shut-offs and valves are crucial for controlling your home’s water flow, and HandyPros is here to ensure they’re working as they should. Whether you need new shut-off valves installed, existing ones repaired, or are dealing with leaks, our qualified Pros provide reliable service to keep your plumbing system operating safely and efficiently.",
      },
    ],
  },

  {
    id: "electrical",
    thumbnail: electrical,
    title: "Electrical",
    desc: "HandyPros offers professional electrical maintenance and installation services to ensure your home stays safe and functional. From installing new light fixtures and ceiling fans to troubleshooting faulty outlets or wiring, our skilled HandyPros are equipped to handle all your minor electrical needs. We prioritize safety and precision in every job, delivering efficient, high-quality solutions tailored to your home. With HandyPros, you can count on reliable service and a hassle-free experience, keeping your electrical systems running smoothly.",
    accordions: [
      {
        title: "Outlets and Switches",
        desc: "HandyPros provides expert installation, repair, and replacement services for outlets and switches to ensure your home’s electrical system is both safe and functional. Whether you need to upgrade to modern outlets, install dimmer switches, or troubleshoot an unresponsive outlet, our skilled HandyPros have you covered. We ensure all installations meet safety standards, giving you peace of mind with every switch you flip.",
      },
      {
        title: "Interior Lighting",
        desc: "Brighten up your living space with HandyPros' interior lighting services. From installing new light fixtures and chandeliers to upgrading to energy-efficient LED lighting, we help create the perfect ambiance for your home. Our team handles everything from wiring to installation, ensuring your lights function flawlessly while enhancing the look and feel of your interiors.",
      },
      {
        title: "Outdoor Lighting",
        desc: "Illuminate your outdoor areas with HandyPros' professional outdoor lighting services. Whether you’re looking to enhance your curb appeal, improve security, or create a welcoming atmosphere, we can install and repair landscape lights, motion sensors, and pathway lighting. Our team ensures that your outdoor lighting is functional, weather-resistant, and tailored to your home’s exterior.",
      },
      {
        title: "Troubleshooting Electrical Issues",
        desc: "Experiencing flickering lights, tripped breakers, or dead outlets? HandyPros is here to help with all your electrical troubleshooting needs. Our experienced HandyPros quickly diagnose and resolve electrical problems, ensuring your home remains safe and functional. Count on HandyPros to get to the root of the problem and provide effective, lasting solutions.",
      },
    ],
  },

  {
    id: "flooring",
    thumbnail: flooring,
    title: "Flooring",
    desc: "HandyPros offers a variety of flooring services. Luxury Vinyl Plank flooring is our most requested flooring service, however our Pros can also install Tile, Peel n Stick and laminate flooring. For our flooring services, we recommend an in person estimate. For a ballpark estimate, please email us: info@stlhandypros.com",
    accordions: [
      {
        title: "Luxury Vinyl Flooring",
        desc: "HandyPros specializes in the installation and repair of luxury vinyl flooring, offering a durable, water-resistant option that mimics the look of natural materials like wood or stone. Whether you're upgrading your space or need repairs, our team ensures a flawless finish that’s both stylish and practical, perfect for high-traffic or moisture-prone areas.",
      },
      {
        title: "Laminate Flooring",
        desc: "Looking for an affordable, attractive flooring option? HandyPros provides expert laminate flooring installation, offering the look of hardwood or tile without the high cost. Our skilled team ensures a seamless installation, delivering a durable, scratch-resistant surface that stands up to daily wear and tear while enhancing the beauty of your home.",
      },
      {
        title: "Ceramic Tile",
        desc: "Transform your space with the timeless elegance of ceramic tile. HandyPros offers professional ceramic tile installation and repairs for floors, walls, and backsplashes. Ideal for kitchens, bathrooms, or entryways, ceramic tile is both durable and easy to maintain, and our expert installation ensures your tiles are perfectly aligned and grouted for a flawless finish.",
      },
      {
        title: "Hardwood Floors",
        desc: "Nothing beats the classic beauty of hardwood floors. HandyPros provides professional hardwood floor installation and repairs, bringing warmth and elegance to any room. Whether you're installing new hardwood or refinishing existing floors, our team ensures a polished, long-lasting result that adds value and charm to your home.",
      },
      {
        title: "Carpet",
        desc: "Carpet giving you a bit of a stretch? HandyPros can help! While we don't do full carpet installation or cleaning, we specialize in stretching and minor repairs to keep your carpets looking great and free of wrinkles. Whether it’s a small fix or smoothing out those unsightly bumps, we’ll have your carpet looking as good as new in no time.",
      },
    ],
  },

  {
    id: "drywall",
    thumbnail: drywall,
    title: "Drywall",
    desc: "At HandyPros, we offer comprehensive drywall services to enhance and repair the walls of your home professionally. From patching up holes and applying custom textures to expert drywall installation, our skilled team ensures a flawless finish for every project. Whether you’re looking to refresh your space with a new look or need repairs to keep your walls looking pristine, HandyPros delivers high-quality workmanship and attention to detail. Trust us to handle your drywall needs efficiently, so you can enjoy beautifully finished walls that stand the test of time.",
    accordions: [
      {
        title: "Drywall Patching",
        desc: "Got a hole in your drywall? HandyPros can patch it up with precision and care. Whether it's a small dent or a larger hole, our team will seamlessly blend the patch with the surrounding wall, ensuring a smooth, flawless finish that’s ready for paint or wallpaper.",
      },
      {
        title: "Texturing",
        desc: "Give your walls some character with our texturing services. HandyPros offers a variety of drywall texturing options to add depth and style to your space. From classic knockdown to modern swirl patterns, we’ll help you achieve the perfect look that complements your home’s decor.",
      },
      {
        title: "Drywall Installation",
        desc: "Starting fresh or renovating? HandyPros provides expert drywall installation to give your walls a clean, professional finish. Our team handles everything from hanging and taping to finishing, ensuring your new drywall is installed flawlessly and ready for painting or decorating.",
      },
      {
        title: "Plaster",
        desc: "Meet plaster, drywall’s cranky uncle! While drywall is smooth and modern, plaster likes to show off its textured, old-school charm. HandyPros can handle your plaster needs with expertise, whether it's patching up those quirky cracks or repairing plasterwork to its former glory. Sometimes a little bit of plaster is just what your home needs to bring a touch of classic elegance back into the mix!",
      },
    ],
  },

  {
    id: "painting",
    thumbnail: painting,
    title: "Painting",
    desc: "HandyPros brings color and vibrancy to your home with our expert interior and exterior painting services. Whether you’re looking to refresh your living space with a new coat of paint or enhance your home’s curb appeal with a fresh exterior finish, our team provides professional, high-quality results. We handle every detail, from surface preparation to final touches, ensuring a smooth, beautiful finish that lasts. Trust HandyPros to transform your home with impeccable painting services that brighten up every corner.",
    accordions: [
      {
        title: "Interior Paint",
        desc: "Transform the look and feel of your home’s interior with HandyPros’ professional painting services. We handle everything from selecting the perfect color to applying a flawless finish. Whether you’re refreshing a single room or painting your entire home, our attention to detail ensures a clean, vibrant result that enhances your living space.",
      },
      {
        title: "Exterior Paint",
        desc: "Enhance your home’s curb appeal with HandyPros’ expert exterior painting services. We provide a thorough preparation process and high-quality finishes that stand up to the elements. From updating your home’s facade to painting trim and shutters, we ensure your exterior looks fresh, inviting, and well-maintained.",
      },
      {
        title: "Accent Walls",
        desc: "Add a splash of style with HandyPros’ accent wall painting services. Whether you want to highlight a feature wall or create a bold statement in any room, we help you choose the perfect color and finish to make your accent wall stand out. Our precise application ensures a striking, professional look that complements your home’s decor.",
      },
      {
        title: "Basement Paint",
        desc: "Brighten up your basement with HandyPros’ specialized painting services. We tackle everything from prepping and priming to applying high-quality paints designed to handle basement conditions. Whether you’re turning your basement into a cozy living area or just freshening up the space, we deliver a smooth, durable finish that enhances the room.",
      },
      {
        title: "Cabinet Paint",
        desc: "Revitalize your kitchen or bathroom with HandyPros’ cabinet painting services. We offer expert refinishing to give your cabinets a fresh, modern look without the need for costly replacements. Our process ensures a smooth, durable finish that rejuvenates your space and complements your home’s style.",
      },
      {
        title: "Touch Up Paint",
        desc: "Keep your home looking pristine with HandyPros’ touch-up painting services. Whether you’ve got minor scuffs, scratches, or areas that need a bit of refreshing, we provide precise touch-ups that blend seamlessly with your existing paint. Our goal is to make your walls look flawless and well-maintained.",
      },
    ],
  },

  {
    id: "furniture-assembly",
    thumbnail: furnitureAssembly,
    title: "Furniture Assembly",
    desc: "Got the new furniture in and found out it’s in a box with 200+ pieces? HandyPros can help! Furniture assembly is tedious work, and must be done carefully to ensure proper assembly. Please expect 2-4 hours per piece depending on how complex and high end the furniture is. Typically the more expensive it is to purchase, the more complicated the assembly process to make sure it is sound. ",
    accordions: [
      {
        title: "Residential Furniture Assembly",
        desc: "HandyPros makes home setup a breeze with our residential furniture assembly services. Whether you’ve just bought a new bed, dresser, or entertainment center, our skilled team assembles your furniture quickly and efficiently. We ensure every piece is assembled correctly, so you can enjoy your new furniture without any hassle.",
      },
      {
        title: "Commercial Furniture Assembly",
        desc: "Upgrade your office or business space with HandyPros’ commercial furniture assembly services. We handle everything from desks and chairs to shelving and conference tables, ensuring that your workspace is set up efficiently and professionally. Our team works with minimal disruption to your operations, delivering high-quality assembly that meets your business needs.",
      },
      {
        title: "Appliance Installation",
        desc: "​​HandyPros offers reliable appliance installation services to ensure your new appliances are set up correctly and ready for use. From refrigerators and dishwashers to washers and dryers, our experienced team handles every aspect of installation with precision and care. We ensure proper connections, level installations, and thorough testing to make sure everything runs smoothly. Trust HandyPros for a hassle-free installation experience, so you can start enjoying your new appliances right away.",
      }
    ],
  },

  {
    id: "appliance",
    thumbnail: applianceInstallation,
    title: "Appliance",
    desc: "Our Pros can install Gas and Electric Ranges, over the stove microwaves and hood vents, refrigerators and water lines, dishwashers, disposals, washers and dryers. ",
    accordions: [
      {
        title: "Ranges",
        desc: "Ready to cook up a storm? HandyPros expertly installs your new range, ensuring it’s properly connected and functioning safely. Whether you’re upgrading to a sleek new model or replacing an old one, we handle the installation with precision to get you cooking in no time.",
      },
      {
        title: "Refrigerator",
        desc: "Keep your food fresh with HandyPros’ professional refrigerator installation services. We ensure your new fridge is installed correctly, connected to the power and water supply (if needed), and leveled for optimal performance. Enjoy the convenience of a properly installed refrigerator without the hassle.",
      },
      {
        title: "Dishwasher",
        desc: "Upgrade your kitchen with a new dishwasher installed by HandyPros. Our team handles the installation, including connecting the water supply and ensuring proper drainage. We’ll have your dishwasher up and running efficiently, so you can enjoy sparkling clean dishes with minimal effort.",
      },
      {
        title: "Washers and Dryers",
        desc: "HandyPros takes the stress out of setting up your new washer and dryer. We provide expert installation services, making sure both machines are properly connected and leveled for optimal performance. From hookups to testing, we ensure your laundry appliances work flawlessly.",
      },
      {
        title: "Disposals",
        desc: "HandyPros provides seamless installation of your new garbage disposal, ensuring it’s securely fitted and properly connected. We handle all the necessary plumbing and electrical work, so you can enjoy a hassle-free experience and keep your kitchen sink running smoothly.",
      },
      {
        title: "Over Range Exhaust / Microwave",
        desc: "Improve ventilation with HandyPros’ expert exhaust fan installation services. We ensure your new fan is installed correctly, vented properly, and operates efficiently to keep your space fresh and free from excess moisture. Our team handles all aspects of installation for optimal performance.",
      },
    ],
  },

  {
    id: "testing-services",
    thumbnail: waterheater,
    title: "Testing Services",
    desc: "At HandyPros, we provide comprehensive testing services to ensure the safety, efficiency, and functionality of your home’s critical systems. From electrical outlets and fixtures to drains, water heaters, and HVAC systems, our skilled team thoroughly inspects and tests each component. We also assess appliances, windows, doors, smoke detectors, and O2 detectors to give you peace of mind that everything is in top working order. Trust HandyPros to keep your home running smoothly with our expert testing services.",
    accordions: [
      {
        title: "Outlets and Switches",
        desc: "HandyPros tests your electrical outlets and switches to ensure they’re functioning correctly and safely. We check for proper voltage, grounding, and any potential issues that could pose a risk. Our thorough testing helps prevent electrical problems and ensures your outlets are reliable.",
      },
      {
        title: "Fixtures",
        desc: "Ensure your light fixtures and other installations are in top working condition with HandyPros’ fixture testing services. We verify that all fixtures are correctly installed, functioning properly, and securely connected to avoid any potential hazards or performance issues.",
      },
      {
        title: "P-Traps & Drains",
        desc: "Ensure your P-traps and Drains are functioning properly with HandyPros’ testing services. We check for leaks and proper drainage to prevent unpleasant odors and maintain your plumbing system’s efficiency. Our detailed inspection helps keep your drains working optimally.",
      },
      {
        title: "Water Heater",
        desc: "HandyPros tests your water heater to ensure it’s operating efficiently and providing consistent hot water. We check for proper temperature settings, leaks, and overall performance to ensure your water heater is running smoothly and meeting your household’s needs.",
      },
      {
        title: "HVAC",
        desc: "Keep your home comfortable with HandyPros’ HVAC testing services. We inspect your heating, ventilation, and air conditioning systems for proper operation, efficiency, and potential issues. Our thorough testing ensures your HVAC system is running effectively and providing optimal climate control.",
      },
      {
        title: "Appliances",
        desc: "Ensure your appliances are working as they should with HandyPros’ appliance testing services. We check for proper operation, connections, and safety features to make sure your appliances are functioning efficiently and safely, giving you peace of mind.",
      },
      {
        title: "Windows",
        desc: "HandyPros tests your windows to ensure they’re properly sealed, insulated, and functioning smoothly. We check for issues such as drafts, ease of operation, and sealing to ensure your windows contribute to energy efficiency and overall comfort.",
      },
      {
        title: "Doors",
        desc: "Ensure your doors are secure and functional with HandyPros’ door testing services. We inspect for proper alignment, locking mechanisms, and smooth operation. Our testing helps identify any issues that could affect security, insulation, or convenience.",
      },
      {
        title: "Smoke and O2 Detectors",
        desc: "Keep your home safe with HandyPros’ testing of smoke and O2 detectors. We ensure these vital safety devices are functioning properly, with accurate readings and reliable alarms. Regular testing helps protect your home and loved ones from potential hazards.",
      },
    ],
  },

  {
    id: "punchlists",
    thumbnail: punchlists,
    title: "Punchlists",
    desc: "HandyPros offers expert assistance with occupancy and Section 8 inspection punch lists, ensuring your property meets all necessary safety and quality standards. Our team tackles required repairs and adjustments quickly and efficiently, addressing everything from minor fixes to more complex tasks. We help you pass inspections with ease, ensuring your property is ready for occupancy or compliance with Section 8 regulations.",
    accordions: [
      {
        title: "Section 8 Inspections",
        desc: "HandyPros assists landlords in meeting the specific requirements of Section 8 inspections. We address any items on your punch list, ensuring that your property complies with housing standards for safety, functionality, and livability. Our team works quickly to complete necessary repairs so you can pass your inspection with confidence.",
      },
      {
        title: "Residential Occupancy Inspections",
        desc: "Get your property ready for occupancy with HandyPros’ residential HandyPro services. We tackle all required repairs, updates, and adjustments from your punch list, ensuring your home meets local safety and housing regulations. Whether you're moving into a new home or preparing a property for tenants, we help ensure everything is up to code.",
      },
      {
        title: "Make Ready Turns",
        desc: "HandyPros makes rental turnover easy with our Make Ready Turn services. We handle everything from touch-ups and repairs to full cleaning and maintenance, ensuring your property is move-in ready for new tenants. Our fast and reliable service gets your unit back on the market in no time, making the transition smooth and hassle-free.",
      }
    ],
  },

  {
    id: "bathrooms",
    thumbnail: bathroomRepair,
    title: "Bathrooms",
    desc: "HandyPros specializes in bathroom remodeling, transforming outdated or inefficient spaces into stylish, functional retreats. From installing new fixtures and tile to updating plumbing and lighting, our team handles every detail with precision and care. Whether you're looking for a full renovation or just a few upgrades, we work with you to create the perfect bathroom that meets your style, budget, and needs.",
    accordions: [
      {
        title: "Tub Refinishing",
        desc: "Give your bathroom a fresh update without the need for a full replacement. HandyPros offers professional tub refinishing services that restore your existing tub’s surface, eliminating chips, stains, and discoloration. This cost-effective solution brings new life to your bathroom, leaving your tub looking shiny and smooth.",
      },
      {
        title: "Tub Replacement",
        desc: "Upgrade your bathroom with a brand-new tub installed by HandyPros. We handle everything from removing your old tub to installing the new one with precision. Whether you’re looking for a modern soaking tub or a simple replacement, we ensure a flawless installation that enhances both style and functionality.",
      },
      {
        title: "Shower Surrounds and Doors",
        desc: "Transform your shower area with HandyPros’ expert installation of shower surrounds and doors. We specialize in custom solutions, including stunning tile showers, sleek glass doors, and durable surrounds that protect your walls. Our installations are designed to add beauty and practicality, making your shower a luxurious part of your bathroom.",
      },
      {
        title: "Vanities & More",
        desc: "HandyPros provides professional installation of vanities, helping you achieve a polished, organized bathroom space. From stylish mirrors to towel racks and upgraded fixtures, we ensure every detail complements your vanity for a cohesive and modern look. Our precise installation adds both beauty and functionality to your bathroom.",
      },
      {
        title: "Toilets",
        desc: "HandyPros makes toilet installation easy and efficient. Whether you’re upgrading to a water-saving model or replacing an old unit, we ensure a proper fit and secure installation. Our team handles everything, including connecting your new toilet to existing plumbing, leaving your bathroom refreshed and fully functional.",
      },
    ],
  },

  {
    id: "decks",
    thumbnail: decks,
    title: "Decks",
    desc: "HandyPros offers comprehensive deck services to keep your outdoor spaces looking great and standing strong. Whether you need repairs, a fresh coat of stain, or a deep pressure wash, our team provides expert care to restore and maintain the beauty and durability of your deck. Trust HandyPros to help you enjoy your outdoor space for years to come.",
    accordions: [
      {
        title: "Repair",
        desc: "HandyPros offers professional deck repair services to keep your deck safe and sturdy. From replacing damaged boards and fixing railings to addressing structural issues, our team ensures your deck is in top condition so you can enjoy your outdoor space with confidence.",
      },
      {
        title: "Pressure Washing",
        desc: "Bring your deck back to life with HandyPros’ pressure washing services. We remove dirt, grime, and mildew buildup, restoring your deck’s natural beauty and preparing it for staining or sealing. Our thorough cleaning process leaves your deck looking fresh and ready for use.",
      },
      {
        title: "Stain",
        desc: "Protect and beautify your deck with HandyPros’ staining services. Our team applies high-quality stains that enhance the wood’s natural grain while providing protection from weather and wear. We ensure a smooth, even finish that not only looks great but also extends the life of your deck.",
      }
    ],
  },

  {
    id: "siding-and-gutters",
    thumbnail: sidingAndGutters,
    title: "Siding & Gutters",
    desc: "HandyPros helps maintain and protect your home’s exterior with our expert gutter and siding services. From cleaning and repairing gutters to fixing damaged siding, we ensure your home stays in great shape, without the need for full replacements. Count on HandyPros to keep your exterior looking sharp and functioning well.",
    accordions: [
      {
        title: "Gutters",
        desc: "HandyPros provides reliable gutter cleaning and repair services to keep your home safe from water damage. We clean out leaves, debris, and blockages to ensure proper drainage, and we fix any damaged sections to keep your gutters functioning smoothly. While we don’t install full gutter systems, we keep your existing gutters in top shape.",
      },
      {
        title: "Siding",
        desc: "HandyPros specializes in siding repairs to keep your home’s exterior looking great and properly sealed. From fixing cracked or loose panels to replacing small sections of damaged siding, we restore your home’s curb appeal and provide essential protection. While we don’t handle full siding installations, our repairs ensure your home stays well-protected from the elements.",
      }
    ],
  },

  {
    id: "conrete-and-asphalt",
    thumbnail: concreteAndAsphalt,
    title: "Concrete & Asphalt ",
    desc: "HandyPros offers expert asphalt and concrete services to keep your driveways, sidewalks, and other surfaces in excellent condition. Whether you need repairs, patching, or sealing, we ensure your surfaces are safe, smooth, and long-lasting. Trust HandyPros for quality care and attention to detail for all your asphalt and concrete needs.",
    accordions: [
      {
        title: "Concrete Repair",
        desc: "HandyPros provides professional concrete repair services, restoring the integrity and appearance of your driveways, sidewalks, and patios. From filling cracks to fixing broken or uneven sections, we ensure your concrete surfaces are safe, durable, and visually appealing.",
      },
      {
        title: "Asphalt Patching",
        desc: "Keep your asphalt surfaces in top condition with HandyPros’ asphalt patching services. We expertly repair potholes, cracks, and other damage, restoring the smoothness and longevity of your asphalt. Our patching services ensure a safer and more durable surface for your driveway or parking area.",
      },
      {
        title: "Asphalt Sealing",
        desc: "Extend the life of your asphalt with HandyPros’ sealing services. Our high-quality sealants protect your asphalt from weather damage, wear, and tear, while also enhancing its appearance. Regular sealing keeps your asphalt surfaces looking great and functioning properly for years to come.",
      }
    ],
  },

  {
    id: "kitchens",
    thumbnail: kitchenRepair,
    title: "Kitchens",
    desc: "HandyPros enhances your kitchen with expert handyman and remodeling services tailored to your needs. From installing and repairing cabinets to updating countertops, backsplashes, and shelving, we bring your kitchen vision to life with attention to detail and quality craftsmanship. Trust HandyPros to refresh and upgrade your kitchen with seamless, stylish solutions.",
    accordions: [
      {
        title: "Cabinets",
        desc: "HandyPros handles both cabinet installation and repair with precision. Whether you’re adding new cabinets or fixing existing ones, our team ensures a perfect fit and finish. We focus on enhancing the functionality and appearance of your kitchen with expertly installed and repaired cabinets.",
      },
      {
        title: "Countertops",
        desc: "Upgrade your kitchen with HandyPros’ countertop services. We specialize in installing Formica and butcher block countertops, providing a range of stylish and functional options. While we don’t handle granite or quartz, our choices offer quality and durability for your kitchen surface needs.",
      },
      {
        title: "Backsplash",
        desc: "Refresh your kitchen with a new backsplash installed by HandyPros. We offer a variety of stylish and functional options to enhance your kitchen’s look and protect your walls from spills and splashes. Our precise installation ensures a polished, professional finish.",
      },
      {
        title: "Pulls",
        desc: "HandyPros provides installation of cabinet pulls and handles to update the look and functionality of your kitchen cabinets. We offer a range of styles and finishes to complement your kitchen’s design, ensuring a cohesive and modern look.",
      },
      {
        title: "Shelving",
        desc: "Maximize your kitchen storage with HandyPros’ shelving installation services. We provide custom solutions to add functional and attractive shelving that fits your space and needs. Our team ensures sturdy, well-installed shelving that enhances your kitchen’s organization and style.",
      },
    ],
  },

  {
    id: "windows",
    thumbnail: windows,
    title: "Windows",
    desc: "HandyPros offers comprehensive window services to keep your home bright and functional. From repairing and installing windows to fixing screens and adding stylish coverings, we provide expert solutions to enhance your home’s appearance and energy efficiency. Trust HandyPros for reliable and professional window care.",
    accordions: [
      {
        title: "Window Repair",
        desc: "HandyPros handles all types of window repairs, addressing issues like cracked glass and malfunctioning mechanisms. Our repair services restore your windows to proper working condition, improving both their function and appearance.",
      },
      {
        title: "Screens",
        desc: "Keep your windows in top shape with HandyPros’ screen repair and replacement services. We fix torn or damaged screens and offer new replacements to keep bugs out and fresh air in, ensuring your windows are both functional and efficient.",
      },
      {
        title: "Window Installation",
        desc: "Upgrade your home with new window installations by HandyPros. We handle everything from selecting the right windows to precise installation, ensuring a perfect fit and finish. Our expert installation improves your home’s energy efficiency, aesthetics, and value.",
      },
      {
        title: "Window Coverings",
        desc: "Enhance your privacy and style with HandyPros’ window covering services. We install a variety of options, including blinds, shades, and curtains, tailored to fit your windows and decor. Our team ensures a seamless installation for functional and beautiful window treatments.",
      },
    ],
  },

  {
    id: "doors",
    thumbnail: doorInstallation,
    title: "Doors",
    desc: "HandyPros provides expert door services to enhance the functionality and aesthetics of your home. Whether you need new exterior or interior doors, repairs, or upgrades like locksets and screen doors, our team delivers quality craftsmanship and attention to detail. Trust HandyPros to keep your doors in top condition and perfectly suited to your home.",
    accordions: [
      {
        title: "Exterior Doors",
        desc: "Upgrade your home’s curb appeal and security with HandyPros’ exterior door installation services. We offer a range of styles and materials to enhance both the look and functionality of your entryways, ensuring a perfect fit and finish that boosts your home’s exterior.",
      },
      {
        title: "Interior Doors",
        desc: "Transform your interior spaces with HandyPros’ interior door installation services. Whether you’re updating old doors or installing new ones, we provide a variety of styles to complement your home’s decor and ensure smooth, reliable operation.",
      },
      {
        title: "Door Repair",
        desc: "HandyPros specializes in door repairs, addressing issues like sticking, warping, or damaged hardware. Our repair services restore the function and appearance of your doors, ensuring they operate smoothly and enhance the comfort and security of your home.",
      },
      {
        title: "Locksets",
        desc: "Enhance your home’s security with HandyPros’ lockset installation services. We install a variety of locksets, including deadbolts and handle locks, to improve safety and convenience. Our expert installation ensures your locks work seamlessly and provide reliable protection.",
      },
      {
        title: "Screen Doors",
        desc: "Keep your home fresh and breezy with HandyPros’ screen door installation services. We provide durable and attractive screen doors that allow for ventilation while keeping insects out. Our installation ensures a perfect fit and smooth operation for your screen doors.",
      },
    ],
  },

  {
    id: "preventative-maintenance",
    thumbnail: preventative,
    title: "Preventative Maintenance",
    desc: "Enhance the lifespan of your HVAC system through consistent maintenance. Enroll in the HandyPros Basic Service Plan to ensure optimal performance for your Furnace and AC Condenser. Annual HVAC Maintenance / Tuneup, FREE Filters 4 times a year, installed!, Priority Booking, 10% off all additional handyman services, 30 additional minutes of FREE handyman services during your initial visit. Great option for landlords, multi-units and short term rentals!",
    accordions: [
      {
        title: "Fire Extinguisher Exchange",
        desc: "Keeping your fire extinguisher up to date not only provides an important safety feature, it also is required by most insurance companies. Set it and forget it with STL HandyPros annual Fire Extinguisher exchange. Benefits:",
        bullets: [
          "Annual replacement of Fire Extinguisher with new date adhered to the device.",
          "Photos sent after replacement for insurance purposes.",
          "Includes fire extinguisher"
        ]
      },
      {
        title: "Plumbing Inspection",
        desc: "STL HandyPros offers a comprehensive plumbing inspection designed to safeguard your home from potential water damage. Our skilled professionals meticulously examine P-traps, identifying and addressing any leaks promptly. We assess drainage systems to ensure optimal functionality, preventing issues that may lead to water damage. Additionally, our inspection includes thorough checks to ensure toilets are flushing properly, providing a proactive approach to maintaining a leak-free and efficient plumbing system in your home. Annual Benefits:",
        bullets:[
          "Check bathroom and kitchen p-traps for leaks",
          "Check all toilets to ensure they flush properly",
          "Check the stack to ensure it is not cracking or leaking",
          "Run water and test optimal draining",
          "Inspect water heater for rust, water leaks or any other visible issues",
          "30 minutes of time to correct simple issues",
        ]
      },
      {
        title: "Batteries & Bulbs",
        desc: "STL HandyPros offers a meticulous Annual Batteries & Bulbs service plan to keep your home running smoothly. Our Pros conduct thorough checks, ensuring thermostat and remote control batteries are at peak performance. We also inspect and replace smoke detector, smart lock, and O2 sensor batteries as needed for enhanced safety. Additionally, our service includes a comprehensive assessment of interior and exterior lighting bulbs, guaranteeing a well-lit and secure home environment. Trust STL HandyPros for expert maintenance that ensures your home stays powered and illuminated. Benefits:",
        bullets: [
          "Check and replace batteries for all devices as needed",
          "Check interior and exterior bulbs and replace any that are burned out",
          "Includes batteries and bulbs",
        ]
      },
      {
        title: "Exterior Inspection",
        desc: "Our skilled professionals meticulously examine for overgrowth, identify potential hazards such as dead trees, and note any signs of rodent activity or holes that may compromise the integrity of the exterior. With an unwavering commitment to proactive maintenance, our Exterior Inspection ensures your property remains in optimal condition, addressing issues before they escalate. Trust STL HandyPros for a thorough and reliable exterior assessment to safeguard the beauty and integrity of your home. Benefits:",
        bullets: [
          "Inspection of the exterior of the home, noting any defects that would allow rodents to access the interior of the home.",
          "Inspection of the fence line, yard for rodents, and dead trees",
          "Inspection of front and back porches for cracks or issues",
          "Inspect brick / siding for tuck point needs or broken vinyl",
          "Inspect windows for missing screens or cracked glass",
          "Photos provided",
        ]
      },
      {
        title: "Roof Inspection",
        desc: "Ensure the longevity of your home with STL HandyPros' Roof Inspection service. Regular inspections are paramount in preventing leaks and water damage, preserving the integrity of your roof. Our skilled professionals meticulously assess the condition of your roof, identifying potential issues before they escalate. With proactive maintenance, we not only safeguard your home from costly water damage but also extend the overall lifespan of your roof. Trust STL HandyPros for thorough and reliable roof inspections, providing peace of mind and long-term protection for your property. Benefits:",
        bullets: [
          "Pro will walk the roof and inspect valleys, caps and chimneys for debris and other potential issues",
          "Inspect around all pipe stacks, HVAC units, and curbs. Look for lifted seams or open cracks.",
          "Check roof edges and inspect flashing",
          "Check for tree limbs coming across the roof",
          "Check gutters and downspouts",
          "Pro will provide photos of the roof as well as any issues found",
          "30 minutes of labor for any small fixes needed",
          "FREE roof sealant applied if needed"
        ]
      },
    ],
  }
];
