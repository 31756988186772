import React from "react";
import "./testimonials-page.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import CTA from "../../components/cta-section/CTA";
import reviewsliderone from "../../assets/images/reviewsliderone.png";
import reviewslidertwo from "../../assets/images/reviewslidertwo.png";
import reviewsliderthree from "../../assets/images/reviewsliderthree.png";
import reviewsliderfour from "../../assets/images/reviewsliderfour.png";
import reviewsliderfive from "../../assets/images/reviewsliderfive.png";
import reviewslidersix from "../../assets/images/reviewslidersix.png";
import reviewsliderseven from "../../assets/images/reviewsliderseven.png";
import reviewslidereight from "../../assets/images/reviewslidereight.png";
import avatar from "../../assets/images/avatar.png";
import stars from "../../assets/images/stars.png";
import ReviewSlider from "../../components/review-slider/ReviewSlider";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";

export const Testimonials = () => {
  const heroContent = {
    subheading: "Testimonials",
    heading: "Our Customer Stories",
    desc: "Explore heartfelt stories from our satisfied clients. From transformative renovations to dependable fixes, our clients share their experiences, providing a glimpse into the quality and trust they've found with us. Join our community of happy homeowners today!",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };
  const testimonials = [
    {
      image: reviewsliderone,
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      image: reviewslidertwo,
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
    {
      image: reviewsliderthree,
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      image: reviewsliderfour,
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      image: reviewsliderfive,
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
    {
      image: reviewslidersix,
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      image: reviewsliderseven,
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      image: reviewslidereight,
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      image: reviewslidereight,
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      image: reviewsliderseven,
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      image: reviewslidersix,
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      image: reviewsliderone,
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      image: reviewslidertwo,
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
    {
      image: reviewsliderthree,
      firstImageUrl: stars,
      quote:
        "When my door needed urgent attention, STL Handy Pros came to the rescue. Their swift response and efficient repair work saved the day. I'm grateful for their reliability and top-notch service!",
      secondImageUrl: avatar,
      author: "David L. - Fencing Fanatic",
      id: "@davidmoney",
    },
    {
      image: reviewsliderfour,
      firstImageUrl: stars,
      quote:
        "Incredible craftsmanship and attention to detail! The team at STL Handy Pros turned my house into a home. From the first call to the final brushstroke, their dedication to quality and professionalism was outstanding.",
      secondImageUrl: avatar,
      author: "Denny Hilguston",
      id: "@denny.hil",
    },
    {
      image: reviewsliderfive,
      firstImageUrl: stars,
      quote:
        "I can't speak highly enough of STL Handy Pros. Their expertise in carpentry and flooring transformed my space. The level of creativity and precision they brought to my project exceeded my expectations. ",
      secondImageUrl: avatar,
      author: "Emily R. - Renovation Enthusiast",
      id: "@emilyrobert",
    },
  ];
  return (
    <AnimationWrapper>
      <div className="testimonials-page">
        <div className="section hero-banner">
          <div className="row">
            <HeroContent data={heroContent} />
          </div>
        </div>

        <div className="section services">
          <div className="container">
            <div className="head">
              <h4>
                <span class="line"></span>Success Stories
              </h4>
              <h3>Client Journeys: Transformative Home Experiences</h3>
              <p>
                Dive into the success stories of homeowners who turned their
                vision into reality. Discover how our skilled team brought
                dreams to life, creating spaces that not only meet expectations
                but exceed them. Your success story begins here.
              </p>
            </div>
            <ReviewSlider reviews={testimonials} />
          </div>
        </div>

        <CTA
          heading={"Book Now"}
          subheading={"Own a Piece of the Brand America Trusts – Franchises Available"}
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025."
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};
