import React, { useState } from "react";
import "./about-us.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import appointment from "../../assets/images/appointment.png";
import imgone from "../../assets/images/about-us-one.png";
import dedicatedone from "../../assets/images/dedicated-one.png";
import dedicatedtwo from "../../assets/images/dedicated-two.png";
import dedicatedthree from "../../assets/images/dedicated-three.png";
import afterarrow from "../../assets/images/afterarrow.png";
import drillerman from "../../assets/images/driller-man.png";
import ScheduleAppointment from "../../components/schedule-appointment/ScheduleAppointment";
import videobanner from "../../assets/images/videobanner.png";
import { Link } from "react-router-dom";
import imgnoone from "../../assets/images/no-1.png";
import imgnotwo from "../../assets/images/no-2.png";
import imgnothree from "../../assets/images/no-3.png";
import imgnofour from "../../assets/images/no-4.png";
import imgnofive from "../../assets/images/no-5.png";
import team1 from "../../assets/images/team-1.png";
import team2 from "../../assets/images/team-2.png";
import team3 from "../../assets/images/team-3.png";
import homeau from "../../assets/images/homeau.png";
import authbadge from "../../assets/images/uth-badge.png";
import autharrow from "../../assets/images/uth-arrow.png";
import coreman from "../../assets/images/core-man.png";
import mainmastro from "../../assets/images/mainmastro.jpg";


const AboutUs = () => {
  const heroContent = {
    subheading: "About us",
    heading: "Meet STL Handypros",
    desc: "At STL HandyPros, we stand out for our commitment to excellence. With our skilled team, prompt service, and dedication to customer satisfaction, choosing us means choosing reliability, expertise, and a hassle-free experience for all your home maintenance and improvement needs.",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="about-us">
      <div className="section hero-banner">
        <div className="row">
          <HeroContent data={heroContent} />
        </div>
      </div>
      <div className="section schedule-appointment">
        <div className="container main-container">
          <div className="row">
            <div className="col larger">
              <div className="head">
                <h4>
                  <span class="line"></span>About The Brand
                </h4>
                <h3>STL Handypros: Excellence in Every Detail</h3>
                <p>
                  At STL Handypros, we believe that your home deserves the best
                  care possible. Founded with a passion for quality
                  craftsmanship and exceptional customer service, our brand
                  stands as a testament to reliability and dedication in the
                  handyman industry.
                </p>
              </div>
              <img src={imgone} alt="imgone" className="imgone" />
              <div className="content-below">
                <div className="content">
                  <h4>Our Mission:</h4>
                  <p>
                    To provide superior handyman services that enhance the
                    comfort, functionality, and aesthetics of your home. We aim
                    to build lasting relationships with our clients through
                    trust, transparency, and outstanding results.
                  </p>
                </div>
                <div className="content">
                  <h4>Our Values:</h4>
                  <ul>
                    <li>
                      Quality: We ensure every job, big or small, is completed
                      to the highest standards.
                    </li>
                    <li>
                      Customer Satisfaction: Your happiness is our priority, and
                      we strive to exceed your expectations.
                    </li>
                    <li>
                      Integrity: Honesty and transparency guide all our
                      interactions and decisions.
                    </li>
                    <li>
                      Innovation: We continuously seek new methods and
                      technologies to improve our services.
                    </li>
                  </ul>
                </div>
                <div className="content">
                  <h4>Our Promise:</h4>
                  <p>
                    From minor repairs to major renovations, STL Handypros is
                    committed to delivering excellent workmanship and
                    unparalleled service. Our team of skilled professionals is
                    here to make your home improvement journey smooth and
                    hassle-free. Trust STL Handypros to handle your home with
                    the care and expertise it deserves.
                  </p>
                </div>
              </div>
            </div>
            <div className="col small">
              <img src={appointment} alt="appointment" />
              <h4>call us at</h4>
              <Link to={"tel:+13148041097"}>
                {" "}
                <h6>314-804-1097</h6>
              </Link>
              <p>Always locally owned & independently operated.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="dedicated">
        <div className="section main-dedicated">
          <div className="container inner-dedicated">
            <div className="head">
              <h4>
                <span class="line"></span>Why Choose Us
              </h4>
              <h3>Dedicated To Excellence, Focused On our Satisfication</h3>
              <p>
                You inspire us! Your home is your sanctuary. STL Handypros
                provides top-notch customer service and high- quality home
                improvements within your budget. From bathrooms to decks, we
                guarantee a hassle-free experience.
              </p>
            </div>
            <div className="row">
              <div className="col larger">
                <img src={dedicatedone} alt="imgone" className="imgone" />
              </div>
              <div className="col small">
                <img src={dedicatedtwo} alt="imgone" className="imgone" />{" "}
                <img src={dedicatedthree} alt="imgone" className="imgone" />
              </div>
            </div>
            <div className="stripe">
              <div className="stripe-content">
                <div className="inner-content">
                  <h6>
                    25+ <span>years</span>
                  </h6>
                </div>
                <div className="inner-content">
                  <h6>
                    100K+ <span>Customers</span>
                  </h6>
                </div>
                <div className="inner-content">
                  <h6>
                    15+ <span>Services</span>
                  </h6>
                </div>
                <div className="inner-content">
                  <h6>
                    98+ <span>Satisficed</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reasons">
        <div className="section main-reasons">
          <div className="container inner-reasons">
            <div className="head">
              <h3>Five great reasons to book a STL Handypros</h3>
            </div>
            <div className="row">
              <div className="col circle-stripe">
                <div className="inner-circle-stripe">
                  <img src={imgnoone} alt="img-one" />
                  <h6>Same or next day bookings available</h6>
                </div>
                <div className="inner-circle-stripe">
                  <img src={imgnotwo} alt="img-one" />
                  <h6>All services are guaranteed and insured</h6>
                </div>
                <div className="inner-circle-stripe">
                  <img src={imgnothree} alt="img-one" />
                  <h6>Arrival times are specific and agreed</h6>
                </div>
                <div className="inner-circle-stripe">
                  <img src={imgnofour} alt="img-one" />
                  <h6>Premium service from start to finish</h6>
                </div>
                <div className="inner-circle-stripe">
                  <img src={imgnofive} alt="img-one" />
                  <h6>Skilled and dynamic professionals</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section services">
        <div className="container">
          <div className="head">
            <h4>
              <span class="line"></span>100% Satisfied customers
            </h4>
            <h3>Customer Reviews</h3>
            <p>
              Hear from our satisfied customers about their experiences with STL
              Handypros. Discover why we're trusted for top-quality handyman
              services and exceptional customer care.
            </p>
          </div>
          <div className="row">
            <img src={videobanner} alt="videobanner" />
          </div>
        </div>
      </div>
      <div className="section extra-two">
        <div className="container">
          <div className="row">
            <div class="column with-image">
              {/* <img src={coreman} alt="homeau" /> */}
            </div>

            <div class="column">
              <div className="head">
                <h4>
                  <span class="line"></span>Our Core Values
                </h4>
                <h3>What Sets STL Handypros Apart</h3>
                <p>
                  At STL Handypros, we pride ours Fill x Hug ur unwavering
                  commitment to exceptional quality and unparalleled customer
                  satisfaction. Discover the core values that drive.us.every
                  day.........
                </p>
              </div>

              <div className="stripe">
                <div className="col">
                  <img src={authbadge} alt="authbadge" />
                  <h5>Unmatched Quality</h5>
                  <p>We ensure every project meets the highest standards.</p>
                </div>
                <div className="col">
                  <img src={autharrow} alt="authbadge" />
                  <h5>Customer First</h5>
                  <p>
                    Our top priority is customer satisfaction. We aim to exceed
                    your expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team">
        <div className="section main-team">
          <div className="container inner-team">
            <div className="head">
              <h4>
                <span class="line"></span>Our Team
              </h4>
              <h3>Check Out Our Awesome Team </h3>
              <p></p>
            </div>
            <div className="row">
              <div className="col larger">
                <img src={team1} alt="team-one" />
                <h4>Mark Johnson</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dolorum in sit molestiae!
                </p>
              </div>
              <div className="col larger">
                <img src={team2} alt="team-one" />
                <h4>Alex Martinize</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dolorum in sit molestiae!
                </p>
              </div>
              <div className="col larger">
                <img src={team3} alt="team-one" />
                <h4>Ryan Thompson</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dolorum in sit molestiae!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section goal">
        <div className="container">
          <div className="head">
            <h4>
              <span class="line"></span>What Makes HandyPros Special?
            </h4>
            <h3>We Never Stop Trying to Earn Your Business and Referrals</h3>
            <p>
              HandyPros thrives on excellent service, from the office
              scheduler to our professional, contractor grade HandyPros, our
              goal is to earn your next project or referral.
            </p>
          </div>

          <div className="row">
            <div class="column">
              <div className="head">
                <div className="with-flex">
                  <img
                    src={afterarrow}
                    alt="afterarrow"
                    className="afterarrow"
                  />
                  <div className="with-column">
                    <h3>How do we do that?</h3>
                    <ul>
                      <li>
                        Easy scheduling through our online chat, email or
                        voice.
                      </li>
                      <br />
                      <li>
                        Text confirmation and updates regarding your
                        appointment
                      </li>
                      <br />

                      <li>
                        Clear and transparent communication about our pricing.
                      </li>
                      <br />

                      <li>
                        Photos attached to the invoice for your convenience.
                      </li>
                      <br />

                      <li>
                        General Liability insurance and background checked
                        pros for peace of mind.
                      </li>
                      <br />

                      <li>
                        Company uniforms and name badges so you can easily
                        identify your Pro.
                      </li>
                      <br />

                      <li>
                        Highly skilled contractor grade Pros for complicated
                        projects.
                      </li>
                      <br />

                      <li>
                        Pay by credit or debit card for touchless convenience.
                      </li>
                      <br />

                      <li>
                        Financing available! Current rates are only 3.9% for
                        qualified borrowers.
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
                <div className="with-flex">
                  <img
                    src={afterarrow}
                    alt="afterarrow"
                    className="afterarrow"
                  />
                  <div className="with-column">
                    <h3>
                      See the HandyPros difference, book your next appointment
                      today!
                    </h3>
                    {/* <ul>
                        <li>
                          Minimize smaller issues becoming larger, more
                          expensive projects.
                        </li>
                        <li>
                          Provide information on product partners that may add
                          enjoyment and/or improvement to your home… with no
                          strings attached!
                        </li>
                      </ul> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <img src={mainmastro} alt="mainmastro" />
            </div>
          </div>
        </div>
      </div>
      <div className="section tabs">
        <div className="container">
          <div className="tabs-container">
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active-tab" : ""}
                onClick={() => handleTabClick(1)}
              >
                Schedule Appointment
              </button>
              {/* <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Get An Estimate
                </button> */}
            </div>
            <div className="tab-content">
              {activeTab === 1 && <ScheduleAppointment />}
              {/* {activeTab === 2 && <GetAnEstimate />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
