import React, { useState } from "react";
import "./home.scss";
import CTA from "../../components/cta-section/CTA";
import Testimonials from "../../components/testimonials/Testimonials";
import ServiceBanner from "../../components/service-banner/ServiceBanner";
import homeau from "../../assets/images/homeau.png";
import homemaintain from "../../assets/images/homemaintain.png";
// import refrshing from "../../assets/images/refrshing.png";
import wallball from "../../assets/images/wol-ball.png";
import wallballtwo from "../../assets/images/wol-ball-two.png";
import vectorlarge from "../../assets/images/vectorlarge.png";
import vectortwolarge from "../../assets/images/vectortwolarge.png";
import vectorsmall from "../../assets/images/Vectorsmall.png";
import ScheduleAppointment from "../../components/schedule-appointment/ScheduleAppointment";
// import GetAnEstimate from "../../components/get-an-estimate/GetAnEstimate";
import BannerHero from "../../components/hero-section/BannerHero";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";
import Faq from "../../components/faq/Faq";
import Compersion from "../../components/compersion/Compersion";
import { Link } from "react-router-dom";
import faqData from "../../assets/data/faqData";

const Home = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const accordionData = [
    {
      title: "Who will take my online class for me?",
      content:
        "We will connect you with expert tutors who will take your online class, tests, and assignments and help you pass your online course. Our team of qualified subject tutors will be available 24 hours a day, 7 days a week to answer your questions and assist you with your course work.",
    },
    {
      title: "Is do my online class service legitimate?",
      content:
        "We will connect you with expert tutors who will take your online class, tests, and assignments and help you pass your online course. Our team of qualified subject tutors will be available 24 hours a day, 7 days a week to answer your questions and assist you with your course work.",
    },
    {
      title: "How much it cost for you to take my online class?",
      content:
        "We will connect you with expert tutors who will take your online class, tests, and assignments and help you pass your online course. Our team of qualified subject tutors will be available 24 hours a day, 7 days a week to answer your questions and assist you with your course work.",
    },
    {
      title: "What is your refund policy?",
      content:
        "We will connect you with expert tutors who will take your online class, tests, and assignments and help you pass your online course. Our team of qualified subject tutors will be available 24 hours a day, 7 days a week to answer your questions and assist you with your course work.",
    },
  ];

  const [faq, setFaq] = useState();
  let accordionHandler = (i) => {
    if (faq === i) {
      return setFaq(null);
    }

    setFaq(i);
  };
  return (
    <AnimationWrapper>
      <div className="home">
        <BannerHero />

        <Compersion />

        <div className="section extra-two">
          <div className="container">
            <div className="row">
              <div class="column">
                <img src={homeau} alt="homeau" />
              </div>

              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>About us
                  </h4>
                  <h3>
                    Guaranteed Professional and Reliable Handypros Services
                  </h3>
                  <p>
                    Our HandyPros undergo a thorough background check, are
                    bonded and insured, and have verified skills. Once your job
                    is complete, you will receive receipts and photos along with
                    your invoice, providing transparent pricing, quality work
                    and peace of mind.{" "}
                    <span>
                      <a href="/about">Read More</a>
                    </span>
                  </p>
                </div>

                <div className="stripe">
                  <div className="col">
                    <h5>1,162+</h5>
                    <p>Completed Projects</p>
                  </div>
                  <div className="col">
                    <h5>100%</h5>
                    <p>Satisficed Customers</p>
                  </div>
                  <div className="col">
                    <h5>150+</h5>
                    <p>Professional Handyman</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={wallball} alt="vectorlarge" className="vectorlarge" />
          <img src={wallballtwo} alt="vectorsmall" className="vectorsmall" />
        </div>

        <div className="section tabs">
          <div className="container">
            <div className="tabs-container">
              <div className="tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  Schedule Appointment
                </button>
                {/* <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Get An Estimate
                </button> */}
              </div>
              <div className="tab-content">
                {activeTab === 1 && (
                  <AnimationWrapper>
                    <ScheduleAppointment />
                  </AnimationWrapper>
                )}
                {/* {activeTab === 2 && (
                  <AnimationWrapper>
                    <GetAnEstimate />
                  </AnimationWrapper>
                )} */}
              </div>
            </div>
          </div>
        </div>

        <ServiceBanner />

        <div className="section extra">
          <div className="container">
            <div className="row">
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>Home maintenance
                  </h4>
                  <h3>Home maintenance is as sure as the changing seasons.</h3>
                  <p>
                    For the seasonal maintenance STL HandyPros understands the
                    significance of seasonal maintenance in preserving the
                    integrity and comfort of your home. As the seasons change,
                    so do the demands on your property, making routine
                    maintenance crucial for optimal performance. That's why we
                    offer specialized preventive maintenance packages tailored
                    for landlords, homeowners, and short-term rental owners. Our
                    skilled team is dedicated to ensuring your property is
                    well-prepared for every season, addressing potential issues
                    before they become major concerns. Trust STL HandyPros to
                    safeguard your investment and provide peace of mind through
                    our comprehensive and customizable seasonal maintenance
                    solutions.
                  </p>
                </div>
              </div>
              <div class="column">
                <img src={homemaintain} alt="homemaintain" />
              </div>
            </div>
          </div>
          <img src={wallball} alt="vectorlarge" className="vectorlarge" />
          <img src={wallballtwo} alt="vectorsmall" className="vectorsmall" />
        </div>

        <Testimonials />

        {/* <Faq accordionData={accordionData} /> */}

        <div className="section faq">
          <div className="container">
            <div className="head">
              <h4>
                <span class="line"></span>FAQ
              </h4>
              <h3>Get your answer</h3>
              <p>
                At STL HandyPros, client satisfaction is our guarantee. We pride
                ourselves on ensuring.
              </p>
            </div>

            <div className="faq-wrapper">
              {faqData.map((item) => (
                <div className="accordion" key={item.id}>
                  <div className="item">
                    <h3
                      className="title"
                      onClick={() => accordionHandler(item.id)}
                    >
                      <span>{faq === item.id ? "-" : "+"}</span> {item.question}
                    </h3>
                    <div
                      className={`faq-content ${faq === item.id ? "show" : ""}`}
                    >
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <CTA
          heading={"contact us"}
          subheading={
            "Own a Piece of the Brand America Trusts – Franchises Available"
          }
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025.            "
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};

export default Home;
