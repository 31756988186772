import React from "react";
import "./how-it-works.scss";
import HeroContent from "../../components/hero-content/HeroContent";
import one from "../../assets/images/one.png";
import two from "../../assets/images/two.png";
import three from "../../assets/images/three.png";
import four from "../../assets/images/four.png";
import five from "../../assets/images/five.png";
import six from "../../assets/images/six.png";
import CTA from "../../components/cta-section/CTA";
import Testimonials from "../../components/testimonials/Testimonials";
import FormalCards from "../../components/formal-cards/FormalCards";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";

const HowItWorks = () => {
  const heroContent = {
    subheading: "How it works",
    heading: "Excellent Results",
    desc: "STL HandyPros stands out in the world of handyman services with its streamlined approach and commitment to excellence. Unlike others, we prioritize efficiency and quality in every aspect of our service. From our user-friendly online booking system that simplifies scheduling to our skilled professionals who undergo rigorous vetting and training, we ensure a seamless experience from start to finish. Our dedication to transparency, on-time arrivals, and meticulous workmanship sets us apart, offering a level of service that's not just satisfactory but truly exceptional. Experience the difference with STL HandyPros – where streamlined operations meet wonderful results in every service we provide.",
    buttonPath: "#",
    buttonName: "Schedule Appointment",
  };

  const cardsInfo = [
    {
      id: 1,
      link: "#",
      image: one,
      heading: "Explore Our Services",
      description:
        "Uncover our extensive array of services designed to cater to your unique home improvement requirements. Whether it's plumbing solutions or painting projects, our comprehensive offerings ensure we have everything you need under one roof.",
    },
    {
      id: 2,
      link: "#",
      image: two,
      heading: "Book online or give us a call",
      description:
        "Experience Hassle-Free Service with STL HandyPros! Booking a skilled Pro is just a click away, ensuring a prompt response and confirming your appointment hassle-free. ",
    },
    {
      id: 3,
      link: "#",
      image: three,
      heading: "Communication and Notifications",
      description:
        "Stay in the loop with our 'on the way' text notifications, ensuring you're prepared for your Pro's arrival. After completion, receive photos showcasing the impeccable work done.",
    },
    {
      id: 4,
      link: "#",
      image: four,
      heading: "Easy payment options",
      description:
        "Conveniently settle payments via debit or credit card, offering a seamless and secure transaction experience.",
    },
    {
      id: 5,
      link: "#",
      image: five,
      heading: "Book your Project",
      description:
        "Discover the ease of booking online with STL HandyPros! Our user-friendly platform empowers you to schedule your home repair or improvement services effortlessly. With just a few clicks, select your desired service, choose a convenient time slot, and confirm your appointment from the comfort of your home. No more waiting on hold or navigating complex booking processes—our streamlined online system ensures quick and efficient scheduling, putting you in control of your service needs with ease.",
    },
    {
      id: 6,
      link: "#",
      image: six,
      heading: "Our 5 Star Commitment",
      description:
        "STL HandyPros takes pride in our 5-star rating, a testament to our commitment to customer satisfaction. Our happy customers are at the heart of everything we do. From exceeding expectations to providing exceptional service, our dedicated team consistently earns praise for their professionalism, expertise, and attention to detail. We cherish the trust our customers place in us and continually strive to deliver top-notch service that not only meets but surpasses their needs, ensuring a positive and rewarding experience with every project we undertake.",
    },
  ];

  return (
    <AnimationWrapper>
      <div className="how-it-works">
        <div className="section hero-banner">
          <div className="row">
            <HeroContent data={heroContent} />
          </div>
        </div>

        <div className="section solution">
          <div className="container">
            <div className="head">
              <h4>
                <span class="line"></span>Your Vision, Our Expertise
              </h4>
              <h3>Solutions for your HandyNeeds</h3>
              <p>
                Discover a personalized experience with STL HandyPros crafted
                specifically for your home. From the first consultation to the
                final touches, we customize our services to align perfectly with
                your distinct needs and desires. Your ideal home is within
                reach—let's collaborate to transform your vision into reality.
              </p>
            </div>
            <div className="info-list">
              <div className="info-row">
                {cardsInfo.map((card, index) => (
                  <FormalCards
                    key={card.id}
                    link={card.link}
                    image={card.image}
                    heading={card.heading}
                    description={card.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <CTA
          heading={"Book Now"}
          subheading={"Own a Piece of the Brand America Trusts – Franchises Available"}
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025."
          }
          buttonName={"schedule appointment"}
        />

        {/* <Testimonials /> */}
      </div>
    </AnimationWrapper>
  );
};

export default HowItWorks;
