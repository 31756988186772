import React, { useState } from "react";
import "./compersion.scss";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";
import living from "../../assets/images/living.svg";
import dining from "../../assets/images/dining.svg";
import bedroom from "../../assets/images/bedroom.svg";
import LivingRoomCompersion from "../living-room-compersion/LivingRoomCompersion";

const Compersion = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="compersion">
      <div className="section main-compersion">
        <div className="tabs">
          <div className="head">
            <h4>
              <span class="line"></span>Get inspired
            </h4>
            <h3>Explore real client before & afters.</h3>
            <p></p>
          </div>
          <div className="container">
            <div className="tabs-container">
              <div className="tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  <img src={living} alt="living" /> Living Room
                </button>
                <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  <img src={dining} alt="living" /> Dining Room
                </button>
                <button
                  className={activeTab === 3 ? "active-tab" : ""}
                  onClick={() => handleTabClick(3)}
                >
                  <img src={bedroom} alt="living" /> Bedroom
                </button>
              </div>
              <div className="tab-content">
                {activeTab === 1 && (
                  <AnimationWrapper>
                    <LivingRoomCompersion />
                  </AnimationWrapper>
                )}
                {activeTab === 2 && <AnimationWrapper>hello</AnimationWrapper>}
                {activeTab === 3 && <AnimationWrapper>hello</AnimationWrapper>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compersion;
