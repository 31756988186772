import React from "react";
import "./service-banner.scss";
import ServiceSocial from "../service-social/ServiceSocial";

const ServiceBanner = () => {
  return (
    <div className="service-banner">
      <div className="section">
        <div className="container">
          <div className="head">
            <h4>
              <span class="line"></span>HandyPro Services
            </h4>
            <h3>We Have a Pro for That!</h3>
            <p>
              From mounting TV’s to rental turns, furniture assembly to roof
              leaks, STL HandyPros has a Pro for that. Each of our Pros have
              honed certain skills, when you schedule with us, we match our best
              Pro for your project.
            </p>
          </div>
        </div>
        <ServiceSocial />
      </div>
    </div>
  );
};

export default ServiceBanner;
