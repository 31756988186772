import React from "react";
import "./footer.scss";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/footer_logo.png";
import logo from "../../assets/images/logo-header.png";

const Footer = () => {
  return (
    <div className="main-footer">
      <div className="above-container">
        <h1>Logo</h1>
        {/* <img src={logo} alt="logo" /> */}
      </div>
      <div class="middle-container">
        <div class="column large first-col">
          <h6>Locally Owned & Independently Operated Franchise.</h6>
          <p>
            * Franchise Owners do their best to handle each job with employees.
            At times, based on the type and/or size of a job, a sub-contractor
            may need to be involved. ** Services and packages listed may not be
            available at all locations.** All qualified fixture and installation
            work is referred to our register of insured professionals.
          </p>
          <h6>Contact:</h6>
          <div className="link">
            <Link to={"tel:+13148041097"}>
              {" "}
              <p>314-804-1097</p>
            </Link>
            <Link to={"mailto:info@STLHandyPros.com<"}>
              <p>info@STLHandyPros.com</p>
            </Link>
          </div>
          <div className="soical-icons">
            <Link to={"#"}>
              <FaFacebookF className="icon" />
            </Link>
            <Link to={"#"}>
              <FaInstagram className="icon" />
            </Link>
            <Link to={"#"}>
              <FaTwitter className="icon" />
            </Link>
            <Link to={"#"}>
              <FaLinkedin className="icon" />
            </Link>
          </div>
        </div>
        <div class="column second-col small">
          <h6>Resources </h6>
          <ul>
            <Link to={"#"}>
              <li>Sell Your Home Checklist</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>Contractor Checklist</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>Home Tips & Tricks</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>Service Promise</li>{" "}
            </Link>
          </ul>
        </div>
        <div class="column third-col small">
          <h6>STL Handypros Services </h6>
          <ul>
            <Link to={"#"}>
              <li>Partners</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>Franchise Opportunities</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>Careers</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>FAQs</li>{" "}
            </Link>
            <Link to={"#"}>
              <li>Goals</li>{" "}
            </Link>
          </ul>
        </div>
      </div>
      <div className="bottom-container">
        <hr />
        <p>
          © 2023 STL Handypros | All Rights Reserved
          | <Link to={"#"} > Site Map </Link> | <Link to={"#"} > Privacy Policy </Link> |  <Link to={"#"} > Terms & Conditions </Link> | <Link to={"#"}> Your Privacy Choices </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
