import React, { useEffect, useState } from "react";
import "./review-slider.scss"; // CSS file for styling
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

const ReviewSlider = ({ reviews }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [reviewsPerSlide, setReviewsPerSlide] = useState(8);

  const totalSlides = Math.ceil(reviews.length / reviewsPerSlide);
  useEffect(() => {
    const updateReviewsPerSlide = () => {
      if (window.innerWidth < 768) {
        setReviewsPerSlide(1);
      } else if (window.innerWidth < 992) {
        setReviewsPerSlide(2);
      } else {
        setReviewsPerSlide(8);
      }
    };

    updateReviewsPerSlide();
    window.addEventListener("resize", updateReviewsPerSlide);

    return () => {
      window.removeEventListener("resize", updateReviewsPerSlide);
    };
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === totalSlides - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? totalSlides - 1 : prevSlide - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const renderReviewsForSlide = (slideIndex) => {
    const startIndex = slideIndex * reviewsPerSlide;
    const endIndex = Math.min(
      (slideIndex + 1) * reviewsPerSlide,
      reviews.length
    );

    const activeReviews = reviews.slice(startIndex, endIndex);

    return (
      <div className="review-grid">
        {activeReviews.map((review, index) => (
          <div key={index} className="review-item">
            <div className="main-image">
              <img src={review.image} alt={`image ${index}`} />
            </div>
            <div className="content">
              <div className="images">
                <img src={review.firstImageUrl} alt={`First Image ${index}`} />
              </div>
              <p>{review.quote}</p>
              <div className="admin">
                <img src={review.secondImageUrl} alt={`Second Image ${index}`} />
                <div className="admin-content">
                  <h3>{review.author}</h3>
                  <p>{review.id}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="review-slider">
      <div className="slides">
        {[...Array(totalSlides)].map((_, index) => (
          <div
            key={index}
            className={index === currentSlide ? "slide active" : "slide"}
          >
            {renderReviewsForSlide(index)}
          </div>
        ))}
      </div>

      <div className="navigation">
        <div className="dots">
          {[...Array(totalSlides)].map((_, index) => (
            <span
              key={index}
              className={index === currentSlide ? "dot active" : "dot"}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
        <div className="button-navigation">
          <button onClick={prevSlide}>
            <IoMdArrowBack className="back-icon" />
          </button>
          <button onClick={nextSlide}>
            <IoMdArrowForward className="next-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewSlider;
