const faqData = [
    {
      id: 1,
      question: "How do I book an appointment? ",
      answer:
        "You can email us at info@stlhandypros.com, give us a call at 314-804-1097, message us via chat on the website, or book online. ",
    },
    {
      id: 2,
      question: "How do I pay for services? ",
      answer:
        "We will send a request for deposit on larger projects, and for smaller projects we accept credit or debit cards, or checks made payable to STL HandyPros LLC. ",
    },
    {
      id: 3,
      question: "What is the process for financing? ",
      answer:
        "We offer financing through a third party called “Wizetack”, once we create an estimate, you will see an option to apply for financing. Once approved for financing, approve the estimate, then we get you scheduled and will submit the deposit request, which you can release via a portal on Wizetack. ",
    },
    {
      id: 4,
      question: "Do you offer same day service? ",
      answer:
        "Currently we are booking about one week out for regular jobs and two weeks out on larger projects. There are times we can do a job the following day, but it depends on where it is and how long it will take.",
    },
    {
      id: 5,
      question: "I’ve called and can’t reach someone, how do I get in touch?",
      answer:
        "HandyPros tries to answer every phone call, but often we are already serving other clients and want to give them our undivided attention. Please leave a message for a call back, or email us at info@stlhandypros.com. You can also send a text: 314-220-4105.",
    },
    {
      id: 6,
      question: "Are you hiring?",
      answer:
        "We are always accepting applications to find the best future HandyPros! Please send an email to us if you’re interested in applying: info@stlhandypros.com",
    },
    {
      id: 7,
      question: "Do you offer any discounts? ",
      answer:
        "Yes! We offer a Veterans discount (5%), a seniors discount (3%), and a loyalty discount (3%). Clients must use only one discount at a time. Loyalty discounts are for return clients who have utilized our services 3 or more times in one year. Discounts must be requested before the project is started. ",
    },
    {
      id: 8,
      question: "Can I get a free estimate?",
      answer:
        "Our estimates are $80 to help cover the cost of the trip and work that goes into preparing a professional estimate. We waive our estimate fee if we are hired to do the project. You can also email us photos and details and we can give you a free ballpark estimate, then send an estimator out to confirm if the numbers look good for you. Please email us as many photos and details as you can: info@stlhandypros.com.",
    }
  ];
  
  export default faqData;
  